import React, { useContext, useEffect, useRef, useState } from 'react';
import AccountsFilterContext from '../../contexts/FilterContexts/AccountsFilterContext';
import { DetailedEmissionsProvider } from '../../contexts/Emissions/DetailedEmissionsContext';
import { TdsButton } from '@scania/tegel-react';
import CustomersContext from '../../contexts/Customer/CustomersContext';
import DetailService from './DetailService';
import ProvidersFilterContext from '../../contexts/FilterContexts/ProvidersFilterContext';
import ChevronLeftIcon from '../../UI/atoms/SvgIcons/ChevronLeftIcon';
import ChevronRightIcon from '../../UI/atoms/SvgIcons/ChevronRightIcon';
import FileCSV01Icon from '../../UI/atoms/SvgIcons/FileCSV01Icon';
import './DetailCanvas.css';

function DetailCanvas({ detailCanvasShow, detailCanvasDate, onHide }) {
  const providersFilterContext = useContext(ProvidersFilterContext);
  const accountsFilterContext = useContext(AccountsFilterContext);
  const customersContext = useContext(CustomersContext);
  const [date, setDate] = useState(null);
  const [show, setShow] = useState(false);
  const [setShowTooltip] = useState(false);
  const [gridReady, setGridReady] = useState(false);
  const targetTooltip = useRef(null);
  const detailService = useRef(null);

  const isDateYesterday = () => {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return date === yesterday.toISOString().split('T')[0];
  };

  const navigateDays = (days, validateYesterday) => {
    if (validateYesterday && isDateYesterday()) {
      return;
    }

    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    setGridReady(false);
    setDate(newDate.toISOString().split('T')[0]);
  };

  const handleClose = () => {
    onHide();
  };

  const handleExportOnClick = (e) => {
    detailService.current.handleExportOnClick(e);
  };

  const handleMouseTooltip = (event) => {
    setShowTooltip(event.type === 'mouseenter');
  };

  const getAccountText = () => {
    switch (providersFilterContext.getSelectedItem()?.id) {
      case 'AZURE':
        return 'Subscription';
      case 'AWS':
        return 'Account';
      default:
        return '-';
    }
  };

  const getAccounts = () => {
    let accounts = accountsFilterContext.getSelectedItems();

    if (accounts instanceof Array) {
      return accounts;
    } else if (accounts instanceof Object) {
      return [accounts];
    }
    return undefined;
  };

  useEffect(() => {
    setGridReady(false);
    setShow(detailCanvasShow.toString().toLowerCase() === 'true');
    setDate(detailCanvasDate);
  }, [detailCanvasShow, detailCanvasDate]);

  return (
    <>
      {/* <Overlay target={targetTooltip.current} show={showTooltip} placement="right">
        {(props) => (
          <Tooltip {...props}>
            Here you can see some more details regarding the emissions for the selections, such as{' '}
            {getAccountText().toLowerCase()}, services and date.
          </Tooltip>
        )}
      </Overlay> */}
      {show && (
        <div
          //  onHide={handleClose} placement="end"
          className="detailcanvas-canvas">
          <div
            className="detail-canvas-header"
            // closeButton
          >
            <h5>
              Emissions Details&nbsp;
              <span
                ref={targetTooltip}
                onMouseEnter={handleMouseTooltip.bind(this)}
                onMouseLeave={handleMouseTooltip.bind(this)}
                className="detailcanvas-help-icon d-inline-flex align-items-center">
                {/* <InfoCircleIcon
                  height="22"
                  width="22"
                  strokeColor="var(--gaia-color-base-white)"
                  fillColor="var(--cet-color-secondary-dark)"
                /> */}
              </span>
            </h5>
            <div className="d-flex justify-content-end flex-grow-1 me-4">
              <div className="d-flex p-1">
                <div className="detailcanvas-date-navigation-column">
                  <span
                    title="View previous day"
                    className="detailcanvas-date-navigation-icon"
                    onClick={navigateDays.bind(this, -1, false)}>
                    <ChevronLeftIcon height="34" width="34" />
                  </span>
                </div>
                <div className="detailcanvas-date-text-container">
                  <div className="detailcanvas-date-text-topic">SELECTED DATE</div>
                  <div className="detailcanvas-date-text-main">{date}</div>
                </div>
                <div className="detailcanvas-date-navigation-column">
                  <span
                    title="View next day"
                    className={
                      isDateYesterday()
                        ? 'detailcanvas-date-navigation-icon-disabled'
                        : 'detailcanvas-date-navigation-icon'
                    }
                    onClick={navigateDays.bind(this, 1, true)}>
                    <ChevronRightIcon height="34" width="34" />
                  </span>
                </div>
              </div>
              <div className="d-flex p-1">
                <span
                  title="Export data as CSV"
                  className="detailcanvas-export-icon"
                  onClick={handleExportOnClick.bind(this)}>
                  <FileCSV01Icon backgroundColor="var(--cet-color-secondary-dark)" />
                </span>
              </div>
            </div>
          </div>
          <div className="detailcanvas-canvas-body">
            <DetailedEmissionsProvider
              key={date}
              provider={providersFilterContext.getSelectedItem()?.id}
              accounts={getAccounts()}
              customerId={customersContext.activeCustomer}
              date={date}
              gridReady={gridReady}>
              <DetailService
                ref={detailService}
                setGridReady={setGridReady}
                accountTypeLabel={getAccountText()}
              />
            </DetailedEmissionsProvider>
          </div>
          <div className="tds-u-flex tds-u-p2">
            <TdsButton
              variant="primary"
              className="tds-u-ml2"
              onClick={handleClose}
              size="sm"
              text=" Back to emissions overview"></TdsButton>
          </div>
        </div>
      )}
    </>
  );
}

// Set default properties
// DetailCanvas.defaultProps = {
//   show: false,
//   date: undefined,
//   onHide: undefined
// };

export default DetailCanvas;
