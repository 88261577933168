let environment;
let apiGatewayPublicUrl;
let apiGatewayAuthUrl;
let apiGatewayEmissionsUrl;
let apiGatewayCustomerUrl;
let cognitoUserPool;
let cognitoAppClientId;
let gaiaSiteUrl;
let classicAppSiteUrl;
let oauthDomain;
let oauthRedirectSignIn;
let oauthRedirectSignedIn;
let oauthRedirectSignOut;
let IsLogging;
let IsDemo;
let developersSite;

const hostname = window && window.location && window.location.hostname;
export const app_path_prefix = 'app';
export const app_base_path_with_leading_slash = '/' + app_path_prefix;

if (hostname === 'cet.scania.com' || hostname === 'cet.greta.prod.gf.aws.scania.com') {
  // PROD
  environment = 'PROD';
  apiGatewayPublicUrl = 'https://cet.scania.com/api/public';
  apiGatewayAuthUrl = 'https://cet.scania.com/api/auth';
  apiGatewayEmissionsUrl = 'https://cet.scania.com/api/emissions';
  apiGatewayCustomerUrl = 'https://cet.scania.com/api/customer';
  cognitoUserPool = 'eu-north-1_3HH9RpdWZ';
  cognitoAppClientId = '29gl3tkg0831nsqkc45sh15bt6';
  gaiaSiteUrl = 'https://cet.scania.com/home';
  classicAppSiteUrl = 'https://cet.scania.com/classic/';
  oauthDomain = 'auth.cet.greta.prod.gf.aws.scania.com';
  oauthRedirectSignIn = 'https://cet.scania.com/' + app_path_prefix + '/';
  oauthRedirectSignedIn = 'https://cet.scania.com/' + app_path_prefix + '/';
  oauthRedirectSignOut = 'https://cet.scania.com/' + app_path_prefix + '/logout';
  IsLogging = false;
  IsDemo = false;
  developersSite = 'https://cet.scania.com/doc';
} else if (hostname === 'demo.gaiagen.eu') {
  // DEMO
  environment = 'DEMO';
  apiGatewayPublicUrl = 'https://api.gaiagen.eu/public';
  apiGatewayAuthUrl = 'https://api.gaiagen.eu/auth';
  apiGatewayEmissionsUrl = 'https://api.gaiagen.eu/emissions';
  apiGatewayCustomerUrl = 'https://api.gaiagen.eu/customer';
  cognitoUserPool = 'eu-north-1_3HH9RpdWZ';
  cognitoAppClientId = '29gl3tkg0831nsqkc45sh15bt6';
  gaiaSiteUrl = 'https://gaiagen.eu';
  oauthDomain = 'auth.gaiagen.eu';
  oauthRedirectSignIn = 'https://demo.gaiagen.eu/';
  oauthRedirectSignedIn = 'https://demo.gaiagen.eu/';
  oauthRedirectSignOut = 'https://demo.gaiagen.eu/logout';
  IsLogging = false;
  IsDemo = true;
  developersSite = 'https://cet.scania.com/doc';
} else if (hostname === 'cet.greta.devtest.gf.aws.scania.com') {
  // DEV
  environment = 'DEV';
  apiGatewayPublicUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/public';
  apiGatewayAuthUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/auth';
  apiGatewayEmissionsUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/emissions';
  apiGatewayCustomerUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/customer';
  cognitoUserPool = 'eu-north-1_94Id7sNe8';
  cognitoAppClientId = '2lf50l2sc2039ahd1rfsvj9ebr';
  gaiaSiteUrl = 'https://cet.greta.devtest.gf.aws.scania.com';
  classicAppSiteUrl = 'https://cet.greta.devtest.gf.aws.scania.com/classic/';
  oauthDomain = 'auth.cet.greta.devtest.gf.aws.scania.com';
  oauthRedirectSignIn = 'https://cet.greta.devtest.gf.aws.scania.com/' + app_path_prefix + '/';
  oauthRedirectSignedIn = 'https://cet.greta.devtest.gf.aws.scania.com/' + app_path_prefix + '/';
  oauthRedirectSignOut =
    'https://cet.greta.devtest.gf.aws.scania.com/' + app_path_prefix + '/logout';
  IsLogging = true;
  IsDemo = false;
  developersSite = 'https://cet.greta.devtest.gf.aws.scania.com/doc';
} else {
  // LOCAL
  environment = 'LOCAL';
  apiGatewayPublicUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/public';
  apiGatewayAuthUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/auth';
  apiGatewayEmissionsUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/emissions';
  apiGatewayCustomerUrl = 'https://cet.greta.devtest.gf.aws.scania.com/api/customer';
  cognitoUserPool = 'eu-north-1_94Id7sNe8';
  cognitoAppClientId = '2lf50l2sc2039ahd1rfsvj9ebr';
  gaiaSiteUrl = 'https://cet.greta.devtest.gf.aws.scania.com';
  classicAppSiteUrl = 'https://cet.greta.devtest.gf.aws.scania.com/classic/';
  oauthDomain = 'auth.cet.greta.devtest.gf.aws.scania.com';
  oauthRedirectSignIn = 'http://localhost:3000/' + app_path_prefix + '/';
  oauthRedirectSignedIn = 'http://localhost:3000/' + app_path_prefix + '/';
  oauthRedirectSignOut = 'http://localhost:3000/' + app_path_prefix + '/logout';
  IsLogging = true;
  IsDemo = false;
  developersSite = 'https://cet.greta.devtest.gf.aws.scania.com/doc';
}

let config = {
  HOSTNAME: hostname,
  ENVIRONMENT: environment,
  REGION: 'eu-north-1',
  API_GATEWAY_PUBLIC_URL: apiGatewayPublicUrl,
  API_GATEWAY_AUTH_URL: apiGatewayAuthUrl,
  API_GATEWAY_EMISSIONS_URL: apiGatewayEmissionsUrl,
  API_GATEWAY_CUSTOMER_URL: apiGatewayCustomerUrl,
  COGNITO_USER_POOL: cognitoUserPool,
  COGNITO_APP_CLIENT_ID: cognitoAppClientId,
  GAIA_SITE_URL: gaiaSiteUrl,
  CLASSIC_APP_SITE_URL: classicAppSiteUrl,
  OAUTH_DOMAIN: oauthDomain,
  OAUTH_REDIRECT_SIGN_IN: oauthRedirectSignIn,
  OAUTH_REDIRECT_SIGNED_IN: oauthRedirectSignedIn,
  OAUTH_REDIRECT_SIGN_OUT: oauthRedirectSignOut,
  LOGIN_URL: `https://${oauthDomain}/oauth2/authorize?response_type=code&client_id=${cognitoAppClientId}&redirect_uri=${oauthRedirectSignIn}&scope=email+openid+profile`,
  GOOGLE_LOGIN_URL: `https://${oauthDomain}/oauth2/authorize?response_type=code&client_id=${cognitoAppClientId}&redirect_uri=${oauthRedirectSignIn}&scope=email+openid+profile&identity_provider=Google`,
  IS_LOGGING: IsLogging,
  IS_DEMO: IsDemo,
  DEVELOPERS_SITE: developersSite
};
export default config;
