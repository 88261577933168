import React, { useContext, useState } from 'react';
import CheckboxItem from './CheckboxItem';
import './CheckboxItemList.css';
import ChevronDownIcon from '../../atoms/SvgIcons/ChevronDownIcon';
import ChevronUpIcon from '../../atoms/SvgIcons/ChevronUpIcon';
import { TdsSpinner } from '@scania/tegel-react';

function CheckboxItemList({
  itemsContextType,
  isCollapsable,
  items,
  keyPrefix,
  onSelectionChanged,
  additionalItemControls,
  favouritesEnabled,
  displayed = true,
  label,
  labelPrefix,
  justCollapsed = false
}) {
  const context = useContext(itemsContextType);

  const [collapsed, setCollapsed] = useState(justCollapsed);

  const handleCollapseClicked = () => setCollapsed(isCollapsable && !collapsed);

  const renderItems = () => {
    return context.isFetchingItems && !collapsed ? (
      <div className="d-flex justify-content-center p-4">
        <TdsSpinner size={'sm'}></TdsSpinner>
      </div>
    ) : (
      <div
        className={
          collapsed
            ? 'checkbox-item-list-items checkbox-item-list-items-hide'
            : 'checkbox-item-list-items checkbox-item-list-items-show'
        }>
        {items?.map((item) => (
          <CheckboxItem
            itemsContextType={itemsContextType}
            key={keyPrefix + item.id}
            keyPrefix={keyPrefix}
            id={item.id}
            label={item.label}
            subLabel={item.subLabel}
            onSelectionChanged={onSelectionChanged}
            additionalItemControls={additionalItemControls}
            favouritesEnabled={favouritesEnabled}
          />
        ))}
      </div>
    );
  };

  return displayed ? (
    <>
      <div
        className={
          isCollapsable
            ? 'd-flex checkbox-item-list-label cursor'
            : 'd-flex checkbox-item-list-label'
        }
        onClick={handleCollapseClicked.bind(this)}>
        <div className="me-auto">
          {labelPrefix} {label} ({items?.length})
        </div>
        <div className={isCollapsable ? 'justify-content-end' : 'd-none'}>
          <span title="Expand" className={collapsed ? '' : 'd-none'}>
            <ChevronDownIcon height="20" width="20" />
          </span>
          <span title="Expand" className={collapsed ? 'd-none' : ''}>
            <ChevronUpIcon height="20" width="20" />
          </span>
        </div>
      </div>
      <div className="checkbox-item-list-divider" />
      {renderItems()}
    </>
  ) : null;
}

// Set default properties
// CheckboxItemList.defaultProps = {
//   displayed: true,
//   itemsContextType: null,
//   keyPrefix: null,
//   labelPrefix: '',
//   label: 'ITEMS',
//   items: [],
//   collapsed: false,
//   isCollapsable: true,
//   favouritesEnabled: true,
//   additionalItemControls: null
// };

export default CheckboxItemList;
