import React, { useContext, useEffect, useState } from 'react';

import CompanyTotalsContext from '../../contexts/CompanyTotalsContext/CompanyTotalsContext';

import CustomerAccountsContext from '../../contexts/Customer/CustomerAccountsContext';
import DateFilterContext from '../../contexts/FilterContexts/DateFilterContext';
import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import { Link, Route, Routes } from 'react-router-dom';
import DetailedEmissionsFilter from './DetailedEmissionsFilter';
import CompanyEmissionsFilter from './CompanyEmissionsFilter';

const EmissionsFilter = () => {
  const [activeFilters, setActiveFilters] = useState('detailed');
  const [disabledDetailed, setDisabledDetailed] = useState(false);
  const companyTotalsContext = useContext(CompanyTotalsContext);
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const dateFilterContext = useContext(DateFilterContext);
  const [selectedDates, setSelectedDates] = useState(dateFilterContext.dateRange);

  const filterId = 'Emissions';

  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor === 'detailed' || anchor === 'company') {
      console.log(anchor);
      setActiveFilters(anchor);
    }
  }, []);

  useEffect(() => {
    // Activate Company Total Emissions if that tab is selected
    companyTotalsContext.setActive(activeFilters === 'company');

    // Force back to 'company' if 'detailed' is set but disabled
    if (activeFilters === 'detailed' && disabledDetailed === true) {
      handleFiltersSelection('company');
    }
  }, [activeFilters]);

  useEffect(() => {
    // Activate Company Total Emissions if having no account access
    if (
      customerAccountsContext.accounts === null ||
      customerAccountsContext.accounts === undefined ||
      customerAccountsContext.accounts.length === 0
    ) {
      handleFiltersSelection('company');
      setDisabledDetailed(true);
    } else {
      setDisabledDetailed(false);
    }
  }, [customerAccountsContext.accounts]);

  const handleFiltersSelection = (selectedEventKey) => {
    setActiveFilters(selectedEventKey);
    window.location.hash = selectedEventKey;
  };
  const handleDateFilterChange = (selectedDateRange) => {
    dateFilterContext.onDateFilterChange(selectedDateRange);
  };

  // Handle that Account Filter can be both single and multi selection based on provider
  const getIfMultiSelecionEnabled = () => {
    return true;
  };

  return (
    <>
      <TdsNavigationTabs
        defaultSelectedIndex={0}
        selectedIndex={window.location.pathname.endsWith('/company') ? 1 : 0}>
        <TdsNavigationTab>
          <Link
            to={''}
            onClick={() => {
              setActiveFilters('detailed');
            }}>
            Detailed Emissions
          </Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link
            to={'company'}
            onClick={() => {
              setActiveFilters('company');
            }}>
            Company Emissions
          </Link>
        </TdsNavigationTab>
      </TdsNavigationTabs>
      <div className={'tds-container tds-u-mt2'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <Routes>
              <Route
                index
                element={
                  <DetailedEmissionsFilter
                    filterId={filterId}
                    getIfMultiSelecionEnabled={getIfMultiSelecionEnabled}
                    handleDateFilterChange={handleDateFilterChange}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                  />
                }></Route>
              <Route
                path={'company'}
                element={
                  <CompanyEmissionsFilter
                    setSelectedDates={setSelectedDates}
                    selectedDates={selectedDates}
                    handleDateFilterChange={handleDateFilterChange}
                    filterId={filterId}
                  />
                }></Route>
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmissionsFilter;
