import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import EmissionsContext from '../../contexts/Emissions/EmissionsContext';
import ChartOverview from '../ChartOverview/ChartOverview';
import { getChartSeries, getLineChartOptions } from './LineChartOptions';
import { registerMousePositionHandler } from '../../utilities/MousePosition';
import { getElementCoords } from '../../utilities/ElementCoordinates';
import { getEnergyTotal, getFilteredPeriod, getTotal } from './OverviewCalculations';
import DetailCanvas from './DetailCanvas';
import CompanyTotalsContext from '../../contexts/CompanyTotalsContext/CompanyTotalsContext';
import LoadingContent from '../common/LoadingContent';
import AccountsFilterContext from '../../contexts/FilterContexts/AccountsFilterContext';
import DateFilterContext from '../../contexts/FilterContexts/DateFilterContext';
import ServicesFilterContext from '../../contexts/FilterContexts/ServicesFilterContext';
import LineChartUp01Icon from '../../UI/atoms/SvgIcons/LineChartUp01Icon';
import LineChartTooltip from './LineChartTooltip';

function LineChart(props) {
  const emissions = useContext(EmissionsContext);
  const accountsFilterContext = useContext(AccountsFilterContext);
  const servicesFilterContext = useContext(ServicesFilterContext);
  const dateFilterContext = useContext(DateFilterContext);
  const companyTotalsContext = useContext(CompanyTotalsContext);
  const [total, setTotal] = useState(0);
  const [energyTotals, setEnergyTotals] = useState(0);
  const [filteredPeriod, setFilteredPeriod] = useState({ from: '-', to: '-' });
  const [detailCanvasShow, setDetailCanvasShow] = useState(false);
  const [detailCanvasDate, setDetailCanvasDate] = useState(undefined);
  const [tooltipData, setTooltipData] = useState(undefined);

  // Register handler on mouse position events, allowing tooltip to hide when mouse is outside chart
  registerMousePositionHandler('LineChartTooltip', (position) => {
    let apexChart = document.getElementById('apexchartsbasic');
    let apexCoords = getElementCoords(apexChart);

    if (
      apexCoords !== null &&
      (position.y <= apexCoords.top ||
        position.y >= apexCoords.bottom ||
        position.x <= apexCoords.left ||
        position.x >= apexCoords.right)
    ) {
      // Hide tooltip
      setTooltipData(null);
    }
  });

  const getHeight = () => {
    if (props.height === undefined) {
      return '40rem'; // Default height
    } else {
      return props.height;
    }
  };

  const onDetailCanvasHide = () => {
    setDetailCanvasShow(false);
  };

  const onMouseMove = (data) => {
    // Set data and show/hide tooltip when hovering mouse in chart
    setTooltipData(data);
  };

  const onClickEmissionsDetails = (date) => {
    // Show Emissions Details for a specific date
    setDetailCanvasShow(true);
    setDetailCanvasDate(date);

    // Hide tooltip
    setTooltipData(null);
  };

  useEffect(() => {
    companyTotalsContext.active
      ? setTotal(getTotal(companyTotalsContext))
      : setTotal(getTotal(emissions));
    companyTotalsContext.active
      ? setEnergyTotals(getEnergyTotal(companyTotalsContext))
      : setEnergyTotals(getEnergyTotal(emissions));
    companyTotalsContext.active
      ? setFilteredPeriod(getFilteredPeriod(companyTotalsContext))
      : setFilteredPeriod(getFilteredPeriod(emissions));
  }, [emissions, dateFilterContext.dateRange, companyTotalsContext.data]);

  const showGraph = () => {
    // Handle if Company Totals is active
    if (companyTotalsContext.active) {
      // Do not show graph if data is empty
      return Object.keys(companyTotalsContext.data).length !== 0;
    }

    var accounts = accountsFilterContext.getSelectedItems();

    var accountsSelected = accounts !== undefined;
    if (accounts instanceof Array) {
      accountsSelected = accounts.length > 0;
    }

    if (accountsSelected && servicesFilterContext.getSelectedItems().length > 0) {
      if (
        Object.keys(emissions.data).length !== 0 &&
        emissions.data.Carbon.every((value) => value === null) &&
        emissions.data.Energy.every((value) => value === null)
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <h3>Overview</h3>
            <ChartOverview
              carbon={total}
              energy={energyTotals}
              filteredDateRangeFrom={filteredPeriod.from}
              filteredDateRangeTo={filteredPeriod.to}
              displayed={0}
            />
          </div>
        </div>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <h3>Estimated emissions</h3>
            <div className="position-absolute" style={{ height: getHeight() }}>
              {emissions.isLoadingData || companyTotalsContext.isLoadingData ? (
                <LoadingContent />
              ) : (
                <>
                  {!showGraph() ? (
                    <div className="tds-u-flex tds-u-align-center tds-u-h-100 tds-u-textalign-center">
                      <div className="flex-fill">
                        <span>
                          <LineChartUp01Icon height="130" width="130" />
                        </span>
                        <p>No data to display based on your active filters</p>
                      </div>
                    </div>
                  ) : (
                    <Chart
                      options={
                        companyTotalsContext.active
                          ? getLineChartOptions(
                              companyTotalsContext.data,
                              filteredPeriod,
                              onMouseMove
                            )
                          : getLineChartOptions(emissions.data, filteredPeriod, onMouseMove)
                      }
                      series={
                        companyTotalsContext.active
                          ? getChartSeries(
                              companyTotalsContext.data.Carbon,
                              companyTotalsContext.data.Energy
                            )
                          : getChartSeries(emissions.data.Carbon, emissions.data.Energy)
                      }
                      type="line"
                      height="100%"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <LineChartTooltip data={tooltipData} onClickEmissionsDetails={onClickEmissionsDetails} />
      <DetailCanvas
        detailCanvasShow={detailCanvasShow}
        detailCanvasDate={detailCanvasDate}
        onHide={onDetailCanvasHide}
      />
    </>
  );
}

export default LineChart;
