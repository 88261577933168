import React, { useContext } from 'react';
import AuthContext from '../../contexts/common/AuthContext';

import './EmptyContent.css';

function EmptyContent() {
  const auth = useContext(AuthContext);

  return (
    <div className="empty-content-container">
      <div className="empty-content-text-heading empty-content-spacing-heading">
        WELCOME
        <br />
        {auth.firstName}&nbsp;{auth.lastName}
      </div>
      <div className="empty-content-divider" />
      <div className="empty-content-text-bread empty-content-spacing-bread">
        We&apos;re super happy that you found our application!
        <br />
        <br />
        Unfortunately it seems like you don&apos;t have any active integrations.
        <br />
        If you would like to get a look-and-feel of our application and the future possibilities,
        visit our demo.
        <br />
        <br />
        <br />
        <br />
        <br />
        Please feel free to contact us via e-mail, if you have more questions
        <br />
        <a href="mailto:green.it@scania.com" className="empty-content-text-bread-email">
          green.it@scania.com
        </a>
      </div>
    </div>
  );
}

export default EmptyContent;
