import React from 'react';

function MethodologyAbout() {
  return (
    <div>
      <h2>About</h2>
      <p className="tds-body-01">
        Our ambition is to improve decision-making in businesses that are transforming to a
        sustainability-driven development methodology and to be a hub between business, climate
        science and software development. In order to do this we aim to continuously develop the
        calculation model. As knowledge and expertise in the topic grows, so will our calculations.
      </p>
      <p className="tds-body-01">
        The latest update to our calculation model includes more services and consider more aspects
        of your cloud server usage, such as storage, memory and network along with the embodied
        emissions.
      </p>
    </div>
  );
}

export default MethodologyAbout;
