import React from 'react';
import TeX from '@matejmazur/react-katex';

function MethodologyCompute() {
  return (
    <React.Fragment>
      <h2>Cloud Computing</h2>
      <p className="tds-body-01">
        The energy usage, and therefore also it&apos;s carbon emissions, is estimated from the power
        drawn by the server. The power a server draws is in turn based upon several factors, such as
        memory (RAM), disk and CPU usage.
      </p>
      <p className="tds-body-01">
        Memory and disk usage plays a small role in calculating the energy usage of a server, and
        CPU usage is probably has the largest affect on it. The formula used to estimate CPU energy
        usage originates from{' '}
        <a href={'https://codeascraft.com/2020/04/23/cloud-jewels-estimating-kwh-in-the-cloud'}>
          Cloud Jewels
        </a>
        . It is as follows:
      </p>
      <p className="tds-body-01">
        <TeX
          math={
            '\\text{W}_\\text{avg}=\\text{W}_\\text{min}+\\text{CPU}_\\text{util}\\times(\\text{W}_\\text{max}-\\text{W}_\\text{min})'
          }
        />
      </p>
      <p className="tds-body-01">
        In order convert the average power to energy used we need to know the amount of vCPUS, a
        time span and PUE:
      </p>
      <p className="tds-body-01">
        <TeX
          math={
            '\\text{E}_\\text{est} = \\text{W}_\\text{avg} \\times \\text{vCPU}_\\text{n} \\times T_\\text{run} \\times \\text{PUE}'
          }
        />
      </p>
      <p className="tds-body-01">
        To estimate carbon from energy we need the Carbon Intensity (CI):
      </p>
      <p className="tds-body-01">
        <TeX math={'\\text{Carbon}_\\text{est}=\\text{E}_\\text{est} \\times \\text{CI}'} />
      </p>
    </React.Fragment>
  );
}

export default MethodologyCompute;
