import React from 'react';
import './RecommendationsRelocation.css';
import { app_base_path_with_leading_slash } from '../../config';

function RecommendationsElectricityMap() {
  return (
    <React.Fragment>
      <div className="recommendations-relocation-col-container-image"></div>
      <div className="electricitymap-text-logo">
        Screenshot from:
        <a href="https://app.electricitymap.org/map" rel="noreferrer" target="_blank">
          <img src={app_base_path_with_leading_slash + '/images/electricitymap-logo.svg'} />
        </a>
      </div>
    </React.Fragment>
  );
}

export default RecommendationsElectricityMap;
