import React from 'react';
import './RecommendationsIntro.css';
// import RelocationIcon from '../../UI/atoms/SvgIcons/RelocationIcon';
// import RightsizingIcon from '../../UI/atoms/SvgIcons/RightsizingIcon';
// import ReArchitectureIcon from '../../UI/atoms/SvgIcons/ReArchitectureIcon';
import { TdsBlock } from '@scania/tegel-react';
import RelocationIcon from '../../UI/atoms/SvgIcons/RelocationIcon';
import RightsizingIcon from '../../UI/atoms/SvgIcons/RightsizingIcon';
import ReArchitectureIcon from '../../UI/atoms/SvgIcons/ReArchitectureIcon';

function RecommendationsThreeRs() {
  return (
    <TdsBlock>
      <h2>The Three R&apos;s</h2>
      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <div className={'tds-u-flex tds-u-flex-dir-col tds-u-align-center'}>
              <span className={'recommendations-intro-icon-image'}>
                <RelocationIcon height={'65'} width={'65'} />
              </span>
              <p>Relocation</p>
            </div>
          </div>
          <div className={'tds-col'}>
            <div className={'tds-u-flex tds-u-flex-dir-col tds-u-align-center'}>
              <span className={'recommendations-intro-icon-image'}>
                <RightsizingIcon height={'65'} width={'65'} />
              </span>
              <p>Rightsizing</p>
            </div>
          </div>
          <div className={'tds-col'}>
            <div className={'tds-u-flex tds-u-flex-dir-col tds-u-align-center'}>
              <span className={'recommendations-intro-icon-image'}>
                <ReArchitectureIcon height={'65'} width={'65'} />
              </span>
              <p>Re-architecting</p>
            </div>
          </div>
        </div>
      </div>
    </TdsBlock>
  );
}

export default RecommendationsThreeRs;
