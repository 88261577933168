import React, { useContext, useEffect, useRef, useState } from 'react';
import LoadingContext from '../../contexts/common/LoadingContext';
import SSOContext from '../../contexts/common/SSOContext';
import { TdsButton, TdsLink, TdsTextField } from '@scania/tegel-react';
import { useCookies } from 'react-cookie';
import { styled } from 'styled-components';
import loginImage from './LoginImage.jpg';
import config from '../../config';

const FlexContainer = styled.div`
  display: flex;
  height: 100vh; /* Full viewport height */
  @media (max-width: 600px) {
    justify-content: center;
  }
`;
const LeftPanel = styled.div`
  width: 445px; /* Fixed width */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;
const RightPanelImage = styled.div`
  height: 100%;
  flex-grow: 1;
  width: auto;
  background-image: url(${loginImage});
  background-size: cover;
`;

function LoginPanel() {
  const sso = useContext(SSOContext);
  const loading = useRef(useContext(LoadingContext));
  const [cookies, setCookie] = useCookies(['ssoEmail']);
  const [error, setError] = useState(null);

  function onChange(e) {
    setCookie('ssoEmail', e.target.value, { sameSite: 'strict' });
  }

  const handleLoginClick = (e) => {
    e.preventDefault();
    loading.current.startActivity('SingleSignOn', 'Redirecting to Single Sign-On page');
    sso.fetchSSORedirectURL(cookies.ssoEmail);
  };

  const handleGoogleLoginClick = (e) => {
    e.preventDefault();
    loading.current.startActivity('SingleSignOn', 'Redirecting to Google');
    window.location.href = config.GOOGLE_LOGIN_URL;
  };

  useEffect(() => {
    if (sso.redirectURL === null) {
      setError("Sorry, we don't recognize this email");
      loading.current.stopActivity('SingleSignOn');
    } else if (sso.redirectURL !== undefined) {
      window.location.href = sso.redirectURL;
    }
  }, [sso.redirectURL, loading]);

  return (
    <FlexContainer>
      <LeftPanel>
        <h4>Log in</h4>

        {error && (
          <tds-message variant="error" header="Error" mode-variant="inherit from parent">
            {error}
          </tds-message>
        )}
        <form onSubmit={handleLoginClick}>
          <section className={'tds-u-pb2'}>
            <TdsTextField
              className="tds-u-pb1"
              modeVariant="secondary"
              name="text-field"
              label="Email"
              labelPosition="outside"
              onTdsInput={onChange}
              onTdsChange={onChange}
              placeholder="e.g. example@foo.com"
              value={cookies.ssoEmail}></TdsTextField>
          </section>
          <section>
            <TdsButton
              onClick={handleLoginClick}
              type="submit"
              size="sm"
              fullbleed
              text="Log in"></TdsButton>
          </section>
        </form>
        <div className="tds-u-mt2 tds-u-textalign-center">
          <TdsLink>
            <a href="#" onClick={handleGoogleLoginClick.bind(this)}>
              Sign in with Google
            </a>
          </TdsLink>
        </div>
      </LeftPanel>
      <RightPanel>
        <RightPanelImage alt={'scania bev truck'}></RightPanelImage>
      </RightPanel>
    </FlexContainer>
  );
}

export default LoginPanel;
