import React from 'react';
import config from '../../config';
import { TdsBlock } from '@scania/tegel-react';
import ApiDescriptionContainer from './molecules/ApiDescriptionContainer';
import ApiDescriptionSection from './molecules/ApiDescriptionSection';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/src/styles/hljs';
import DivTdsBody01 from '../../UI/atoms/Typography/DivTdsBody01';
import { ApiPathParameterContainer, LiWithTitle } from './molecules/LiWithTitle';

function DevelopersAccessApiDeleteUserPermissions() {
  return (
    <>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <ApiDescriptionContainer
          description={
            'This endpoint deletes a specific user under a specific customer. The entire user and all permission content for that user will be removed.'
          }
          path={'/v1/auth/customers/'}
          method={'delete'}>
          <ApiDescriptionSection header={'Path Parameters'}>
            <ApiPathParameterContainer>
              <LiWithTitle title={'customer_id'}>
                The id of the customer where to lookup the specific user.
              </LiWithTitle>
              <LiWithTitle title={'user_id'}>
                The id of the user to lookup, usually this is the users e-mail address.
              </LiWithTitle>
            </ApiPathParameterContainer>
          </ApiDescriptionSection>
          <ApiDescriptionSection header={'Required Permissions'}>
            <DivTdsBody01>
              Admin permissions for the specific customer is required in order to get a successful
              response.
            </DivTdsBody01>
          </ApiDescriptionSection>
        </ApiDescriptionContainer>
      </div>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <TdsBlock>
          <h2>Example</h2>
          <h3>Request</h3>
          <SyntaxHighlighter language="sh" style={docco} wrapLongLines>
            {"curl '" +
              config.API_GATEWAY_AUTH_URL +
              '/v1/auth/customers/YourReferenceId/users/user.one@yourcompany.eu\' -X DELETE -H "Authorization: Basic $API_KEY"'}
          </SyntaxHighlighter>
          <h3>Response</h3>
          <h4>Status Codes</h4>
          <code>
            204 <span className="code-general-divider">|</span> 400{' '}
            <span className="code-general-divider">|</span> 401
          </code>
          <h4>
            Payload Data <span style={{ fontSize: '0.75rem' }}>(200)</span>
          </h4>
          Not Applicable due to HTTP response 204 (No Content).
        </TdsBlock>
      </div>
    </>
  );
}

export default DevelopersAccessApiDeleteUserPermissions;
