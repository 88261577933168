import React from 'react';
import { HttpMethodLabel } from '../../../UI/atoms/Typography/HttpMethodLabel';
import DivTdsBody01 from '../../../UI/atoms/Typography/DivTdsBody01';

function ApiDescriptionContainer({ method, path, description, children }) {
  return (
    <>
      <h3>
        <HttpMethodLabel method={method} />
      </h3>
      <h3>{path}</h3>
      <DivTdsBody01>{description}</DivTdsBody01>
      {children}
    </>
  );
}

export default ApiDescriptionContainer;
