import React, { useEffect, useContext, useState } from 'react';
import EmptyContent from '../../components/common/EmptyContent';
import LoadingContent from '../../components/common/LoadingContent';
import config from './../../config';
import CustomersContext from '../../contexts/Customer/CustomersContext';
import CustomerAccountsContext from '../../contexts/Customer/CustomerAccountsContext';

function EmissionsContent(props) {
  const [loadingMessage, setLoadingMessage] = useState(undefined);
  const customersContext = useContext(CustomersContext);
  const customerAccountsContext = useContext(CustomerAccountsContext);

  useEffect(() => {
    if (config.ENVIRONMENT === 'DEMO') {
      // Set initial loading message for DEMO
      setLoadingMessage('Authenticating against DEMO environment');

      // Set next loading message for DEMO after 2,5 seconds
      setTimeout(() => {
        setLoadingMessage('Fetching DEMO emissions data');
      }, 2500);

      // Set next loading message for DEMO after 5 seconds
      setTimeout(() => {
        setLoadingMessage('Preparing DEMO emissions graph');
      }, 5000);

      // Restore loading message after 7,5 seconds
      setTimeout(() => {
        setLoadingMessage(undefined);
      }, 7500);
    }
  }, []);

  return (
    <React.Fragment>
      {customersContext.activeCustomer === undefined ? (
        <LoadingContent loadingMessage={loadingMessage} />
      ) : customersContext.activeCustomer === null ? (
        <EmptyContent />
      ) : customerAccountsContext.accounts === undefined ? (
        <LoadingContent loadingMessage={loadingMessage} />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </React.Fragment>
  );
}

export default EmissionsContent;
