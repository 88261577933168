import React, { useEffect, useContext, useState } from 'react';
import FilterDropdown from '../../molecules/Filter/FilterDropdown';
import HandleOutsideClickAndEsc from '../../atoms/Handlers/HandleOutsideClickAndEsc';
import FilterDropdownButton from '../../molecules/Filter/FilterDropdownButton';
import AccountsFilterContext from '../../../contexts/FilterContexts/AccountsFilterContext';
import ProvidersFilterContext from '../../../contexts/FilterContexts/ProvidersFilterContext';
import ServicesFilterContext from '../../../contexts/FilterContexts/ServicesFilterContext';
import CustomerAccountsContext from '../../../contexts/Customer/CustomerAccountsContext';

function ProvidersFilter(props, { handlers = [] }) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const context = useContext(ProvidersFilterContext);
  const accountsContext = useContext(AccountsFilterContext);
  const servicesContext = useContext(ServicesFilterContext);
  const customerAccountsContext = useContext(CustomerAccountsContext);

  useEffect(() => {
    // Fetch items once filterId has been set
    if (context.filterId) {
      context.fetchItems();
    }
  }, [context.filterId]);

  useEffect(() => {
    // Only fetch items for accounts when CustomerAccountsContext has been loaded
    if (
      customerAccountsContext.accounts !== null &&
      customerAccountsContext.accounts !== undefined
    ) {
      // Fetch items for AccountsContext once a provider has been selected
      accountsContext?.fetchItems(context.getSelectedItem()?.id ?? null);
    }

    // Fetch items for ServicesContext once a provider has been selected
    servicesContext?.fetchItems(context.getSelectedItem()?.id ?? null);
  }, [context.updated, customerAccountsContext.accounts]);

  const onClickDropdown = () => setDisplayDropdown(!displayDropdown);
  const closeDropdown = () => setDisplayDropdown(false);

  return (
    <HandleOutsideClickAndEsc enabled={props.closeOnClickOutside} handler={closeDropdown}>
      <FilterDropdownButton
        itemTypeLabel="provider"
        onClickDropdownHandler={onClickDropdown}
        displayDropdown={displayDropdown}
      />
      <FilterDropdown
        controlId={props.controlId}
        filterId={props.filterId}
        handlers={[...handlers, closeDropdown]}
        displayed={displayDropdown}
        itemTypeLabel="PROVIDERS"
        itemsContextType={ProvidersFilterContext}
        multiSelection={false}
        applyFilterButtonEnabled={false}
        selectionSummaryEnabled={false}
      />
    </HandleOutsideClickAndEsc>
  );
}

// Set default properties
//ProvidersFilter.defaultProps = {
//   controlId: 'DEFAULT', // TODO: Autogenerate unique ID here
//   filterId: undefined,
//   handlers: []
//   closeOnClickOutside: undefined
//};

export default ProvidersFilter;
