import React from 'react';
import { Link } from 'react-router-dom';
import config, { app_base_path_with_leading_slash } from '../../config';

const DevelopersAuthentication = () => {
  return (
    <p className={'tds-body-01'}>
      <h2>Create an API key</h2>
      <p>
        Navigate to your{' '}
        <Link to={app_base_path_with_leading_slash + '/settings/apikey'}>settings page</Link>. to
        create an API key. This function is not available in mobile view.
      </p>
      <h2>Using your API key</h2>
      <p>
        To use your API key, prefix it with <code>Basic</code> add it to the request as a header
        named <code>Authorization</code>.
      </p>
      <h2>Example</h2>
      <p>The following is an example if request is performed as a cURL command:</p>
      <p>
        <code>
          <span className="code-shell-cmd">curl</span> &apos;{config.API_GATEWAY_AUTH_URL}
          /v1/auth/user&apos; <span className="code-curl-options">-X</span> GET{' '}
          <span className="code-curl-options">-H</span> &apos;Authorization: Basic
          &lt;API_KEY&gt;&apos;
        </code>
      </p>
    </p>
  );
};

export default DevelopersAuthentication;
