import React from 'react';

function RecommendationsRelocation() {
  return (
    <React.Fragment>
      <h2>Relocation</h2>
      <p className={'tds-body-01'}>
        Energy is not equal. Aim to use data centers in countries or regions with low CO2 emission
        equivalent per kWh and in regions with cooler climate.
        <ul>
          <li>
            Aim to use data centers in countries with low CO2 emissions per kWh produced, preferebly
            regions where emissions per kWh produced is lower than 50g CO2 equivalent. Region
            emissions can be found at sources such as{' '}
            <a href="https://app.electricitymap.org/map" rel="noreferrer" target="_blank">
              electricitymap.org
            </a>
            .
          </li>
          <li>
            Aim for countries with a low and stable CO2 per kWh over time. This is an indicator that
            the country or region constantly can produce electricity with low CO2 emissions
            equivalent per kWh no matter the variation from its energy source.
          </li>
          <li>
            Aim to use data centers in countries or regions with cooler climate. The cooler the
            climate is, less energy is needed to keep the processors cool. Studies shows that it
            takes about 1.5 times the energy to keep data centers cooled per kWh used from a server
            and that this number can be substantially reduced in cooler climates (European
            Commission, 2017).
          </li>
        </ul>
        <p>To read more about different energy sources, go to:</p>
        <ul>
          <li>IPCC - &quot;Technology-specific Cost and Performance Parameters&quot;</li>
          <li>Wikipedia - &quot;Life-cycle greenhouse-gas emissions of energy sources&quot;</li>
        </ul>
      </p>
    </React.Fragment>
  );
}

export default RecommendationsRelocation;
