import React, { useState } from 'react';
import FilterDropdownButton from '../../molecules/Filter/FilterDropdownButton';
import HandleOutsideClickAndEsc from '../../atoms/Handlers/HandleOutsideClickAndEsc';
import DatePickerCalendar from '../DatePicker/DatePickerCalendar';
import DateDropdownDisplay from '../../atoms/DateSelection/DateDropdownDisplay';
import './DateFilter.css';

const DateFilter = ({
  handleDateFilterChange,
  setSelectedDates,
  selectedDates,
  id,
  dateRange,
  showAsDate = true,
  closeOnClickOutside
}) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const onClickDropdown = () => setDisplayDropdown(!displayDropdown);
  const closeDropdown = () => setDisplayDropdown(false);

  return (
    <span className="position-relative">
      <HandleOutsideClickAndEsc enabled={closeOnClickOutside} handler={closeDropdown}>
        <FilterDropdownButton
          showAsOriginal={true}
          itemTypeLabel={
            <DateDropdownDisplay id={id} showAsDate={showAsDate} dateRange={dateRange} />
          }
          onClickDropdownHandler={onClickDropdown}
          displayDropdown={displayDropdown}
        />
        <div className={displayDropdown ? `date-picker-calendar-wrapper ${id}` : 'd-none'}>
          <DatePickerCalendar
            id={id}
            closeDropdown={closeDropdown}
            displayed={displayDropdown}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            handleDateFilterChange={handleDateFilterChange}
          />
        </div>
      </HandleOutsideClickAndEsc>
    </span>
  );
};

// Set default properties
// DateFilter.defaultProps = {
//   showAsDate: true
// };

export default DateFilter;
