import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './gaia.css';
import './gaia-bootstrap.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Amplify, Auth } from 'aws-amplify';
import config, { app_base_path_with_leading_slash } from './config';

import { LoadingProvider } from './contexts/common/LoadingContext';
import { AuthProvider } from './contexts/common/AuthContext';

import SceneRouter from './SceneRouter';
import LoadingModal from './components/LoadingModal/LoadingModal';
import Settings from './scenes/Settings/Settings';
import ErrorNotFound from './scenes/Error/ErrorNotFound';
import FooterPanel from './components/Footer/FooterPanel';
import Emissions from './scenes/Emissions/Emissions';
import PrivateRoute from './components/common/Auth/PrivateRoute';
import CookieDisclaimer from './components/CookieDisclaimer/CookieDisclaimer';
import Methodology from './scenes/Methodology/Methodology';
import { SSOProvider } from './contexts/common/SSOContext';
import { CustomersProvider } from './contexts/Customer/CustomersContext';
import { CustomerAccountsProvider } from './contexts/Customer/CustomerAccountsContext';
import { CookiesProvider } from 'react-cookie';
import { UserProvider } from './contexts/Auth/UserContext';
// import MyPermissions from './scenes/Settings/MyPermissions';
import { AlertProvider } from './contexts/common/AlertContext';
import { MenuProvider } from './contexts/MenuContext/MenuContext';
import Recommendations from './scenes/Recommendations/Recommendations';
// import NewsModal from './components/NewsModal/NewsModal';
// import GeneralSettings from './scenes/Settings/GeneralSettings';
import { Trends } from './scenes/Trends/Trends';
// import ApiKeySettings from './scenes/Settings/ApiKeySettings';
import { CookieProvider } from './contexts/Cookies/CookieContext';
import ScaniaHeader from './components/MenuNavbar/ScaniaHeader';
import { defineCustomElements } from '@scania/tegel-react';
import ScaniaSideMenu from './components/MenuNavbar/ScaniaSideMenu';
import LoginPanel from './components/LoginPanel/LoginPanel';
import DemoLoginPanel from './components/LoginPanel/DemoLoginPanel';
import Developers from './scenes/Developers/Developers';
import Home from './scenes/Home/Home';
import Integration from './scenes/Integration/Integration';

// import ManagePermissions from './scenes/Settings/ManagePermissions';

defineCustomElements();

const root = ReactDOM.createRoot(document.getElementById('root'));
const getToken = async () => {
  // Get demo token from API if DEMO environment (TODO: Investigate if token must be refreshed from time to time or not..)
  if (config.ENVIRONMENT === 'DEMO') {
    let response = await fetch(config.API_GATEWAY_AUTH_URL + '/v1/demo/token', {
      headers: {
        Host: config.HOSTNAME
      },
      mode: 'cors',
      method: 'GET'
    });
    let data = await response.json();
    return data['Token'];
  }

  // Use normal authentication flow if not DEMO environment
  return (await Auth.currentSession()).idToken.jwtToken;
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: config.OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: config.OAUTH_REDIRECT_SIGN_OUT,
      responseType: 'code',
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: false
      }
    }
  },
  API: {
    endpoints: [
      {
        name: 'public',
        endpoint: config.API_GATEWAY_PUBLIC_URL,
        region: config.REGION
      },
      {
        name: 'auth',
        endpoint: config.API_GATEWAY_AUTH_URL,
        region: config.REGION,
        custom_header: async () => {
          return {
            Authorization: await getToken()
          };
        }
      },
      {
        name: 'idp',
        endpoint: config.API_GATEWAY_AUTH_URL,
        region: config.REGION
      },
      {
        name: 'emissions',
        endpoint: config.API_GATEWAY_EMISSIONS_URL,
        region: config.REGION,
        custom_header: async () => {
          return {
            Authorization: await getToken()
          };
        }
      },
      {
        name: 'customer',
        endpoint: config.API_GATEWAY_CUSTOMER_URL,
        region: config.REGION,
        custom_header: async () => {
          return {
            Authorization: await getToken()
          };
        }
      }
    ]
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <AlertProvider>
          <AuthProvider>
            <SSOProvider>
              <UserProvider>
                <CookieProvider>
                  <LoadingProvider>
                    <CustomersProvider>
                      <MenuProvider>
                        <LoadingModal />
                        {/* <NewsModal /> */}
                        {/* TODO: Add GLOBAL CONTEXTS here if needed */}
                        {/* TODO: Add HEADER or TOP MENU here if needed */}
                        <ScaniaHeader />
                        <ScaniaSideMenu>
                          <CustomerAccountsProvider>
                            <SceneRouter>
                              <>
                                <Routes>
                                  <Route
                                    path={app_base_path_with_leading_slash + '/login'}
                                    element={
                                      config.ENVIRONMENT === 'DEMO' ? (
                                        <DemoLoginPanel />
                                      ) : (
                                        <LoginPanel />
                                      )
                                    }
                                  />
                                  <Route element={<PrivateRoute />}>
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/'}
                                      element={
                                        <Navigate
                                          to={app_base_path_with_leading_slash + '/emissions'}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path="/"
                                      element={
                                        <Navigate
                                          to={app_base_path_with_leading_slash + '/emissions'}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/trends'}
                                      element={
                                        <Navigate
                                          to={app_base_path_with_leading_slash + '/compare'}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/home'}
                                      element={<Home />}
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/compare'}
                                      element={<Trends />}
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/emissions/*'}
                                      element={<Emissions />}
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/methodology/*'}
                                      element={<Methodology />}
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/developers/*'}
                                      element={<Developers />}
                                    />
                                    <Route
                                      path={app_base_path_with_leading_slash + '/integration'}
                                      element={
                                        <Navigate
                                          to={app_base_path_with_leading_slash + '/integration/aws'}
                                        />
                                      }
                                    />
                                    <Route
                                      exact
                                      path={app_base_path_with_leading_slash + '/integration/*'}
                                      element={<Integration />}
                                    />
                                    <Route
                                      path={app_base_path_with_leading_slash + '/recommendations'}
                                      element={<Recommendations />}
                                    />
                                    <Route
                                      path={app_base_path_with_leading_slash + '/developers'}
                                      element={<Developers />}
                                    />
                                    <Route
                                      exact
                                      index
                                      path={app_base_path_with_leading_slash + '/settings/*'}
                                      element={<Settings />}
                                    />
                                  </Route>
                                  <Route
                                    path={app_base_path_with_leading_slash + '/404'}
                                    element={<ErrorNotFound />}
                                  />
                                  <Route
                                    path="*"
                                    element={
                                      <Navigate to={app_base_path_with_leading_slash + '/404'} />
                                    }
                                  />
                                </Routes>
                              </>
                            </SceneRouter>
                          </CustomerAccountsProvider>
                        </ScaniaSideMenu>
                        <FooterPanel />
                        <CookieDisclaimer />
                      </MenuProvider>
                    </CustomersProvider>
                  </LoadingProvider>
                </CookieProvider>
              </UserProvider>
            </SSOProvider>
          </AuthProvider>
        </AlertProvider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
