import React from 'react';
import config from '../../config';
import DevelopersAccessApiGetUser from './DevelopersAccessApiGetUser';
import DevelopersAccessApiGetUserPermissions from './DevelopersAccessApiGetUserPermissions';
import DevelopersAccessApiPatchUserPermissions from './DevelopersAccessApiPatchUserPermissions';
import DevelopersAccessApiPostUserPermissions from './DevelopersAccessApiPostUserPermissions';
import DevelopersAccessApiDeleteUserPermissions from './DevelopersAccessApiDeleteUserPermissions';
import DivTdsBody01 from '../../UI/atoms/Typography/DivTdsBody01';

const DevelopersAccessApp = () => {
  return (
    <>
      <h2>Access API</h2>
      <DivTdsBody01>
        <b>Base URL</b>: <code>{config.API_GATEWAY_AUTH_URL}</code>
      </DivTdsBody01>
      <div className={'tds-container-no-padding'}>
        <div className={'tds-row tds-u-pb1'}>
          <DevelopersAccessApiGetUser />
        </div>
        <div className={'tds-row tds-u-pb1'}>
          <DevelopersAccessApiGetUserPermissions />
        </div>
        <div className={'tds-row tds-u-pb1'}>
          <DevelopersAccessApiPostUserPermissions />
        </div>
        <div className={'tds-row tds-u-pb1'}>
          <DevelopersAccessApiPatchUserPermissions />
        </div>
        <div className={'tds-row tds-u-pb1'}>
          <DevelopersAccessApiDeleteUserPermissions />
        </div>
      </div>
    </>
  );
};

export default DevelopersAccessApp;
