import React from 'react';
import { TrendsFilterProvider } from '../../contexts/Trends/TrendsFilterContext';
import AggregatedProvider from '../../contexts/Emissions/AggregatedEmissionsContext';
import { ProvidersFilterContextProvider } from '../../contexts/FilterContexts/ProvidersFilterContext';
import { AccountsFilterContextProvider } from '../../contexts/FilterContexts/AccountsFilterContext';
import { ServicesFilterContextProvider } from '../../contexts/FilterContexts/ServicesFilterContext';
import { CategoriesFilterContextProvider } from '../../contexts/FilterContexts/CategoriesFilterContext';
import EmissionsContent from '../../components/EmissionsContent/EmissionsContent';
import TrendsFilter from '../../UI/organisms/Trends/TrendsFilter/TrendsFilter';
import TrendsData from '../../UI/organisms/Trends/TrendsData';

export const Trends = () => {
  return (
    <ProvidersFilterContextProvider>
      <AccountsFilterContextProvider>
        <ServicesFilterContextProvider>
          <CategoriesFilterContextProvider>
            <TrendsFilterProvider>
              <AggregatedProvider>
                <EmissionsContent>
                  <div className={'tds-container tds-u-mt2'}>
                    <div className={'tds-row'}>
                      <div className={'tds-col'}>
                        <TrendsFilter />
                        <TrendsData />
                      </div>
                    </div>
                  </div>
                </EmissionsContent>
              </AggregatedProvider>
            </TrendsFilterProvider>
          </CategoriesFilterContextProvider>
        </ServicesFilterContextProvider>
      </AccountsFilterContextProvider>
    </ProvidersFilterContextProvider>
  );
};
