import CustomerHeader from '../../molecules/Trends/CustomHeader';
import {
  AccountCellRenderer,
  EmisssionsCellRenderer,
  EnergyCellRenderer,
  IconCellRenderer,
  columnComparator
} from './TrendsCellRenderer';

export const columnOptions = (trendsAverageData, blurSensitive) => {
  return [
    {
      headerName: 'Provider',
      field: 'Provider',
      flex: 2,
      resizable: true,
      suppressMovable: true,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
    },
    {
      headerName:
        trendsAverageData && trendsAverageData.provider === 'AWS' ? 'Account' : 'Subscription',
      field: 'Account',
      flex: 3,
      resizable: true,
      suppressMovable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { filter: blurSensitive ? 'blur(5px)' : 'none' },
      cellRenderer: AccountCellRenderer,
      valueFormatter: (params) => params.value
    },
    {
      headerName: 'Account Alias',
      field: 'Alias',
      flex: 3,
      hide: true
    },
    {
      headerName: 'Baseline Period Daily Average',
      headerGroupComponent: CustomerHeader,
      children: [
        {
          headerName: 'Emissions, CO\u2082e',
          field: 'BaselineEmissions',
          flex: 3,
          resizable: true,
          sortable: true,
          filter: true,
          suppressMovable: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: EmisssionsCellRenderer
        },
        {
          headerName: 'Energy',
          field: 'BaselineEnergy',
          flex: 3,
          resizable: true,
          sortable: true,
          filter: true,
          suppressMovable: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: EnergyCellRenderer
        }
      ]
    },
    {
      headerName: 'Assessment Period Daily average',
      marryChildren: true,
      headerGroupComponent: CustomerHeader,
      children: [
        {
          headerName: 'Emissions, CO\u2082e',
          field: 'AssessmentEmissions',
          flex: 3,
          resizable: true,
          sortable: true,
          filter: true,
          suppressMovable: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: EmisssionsCellRenderer
        },
        {
          headerName: 'Energy',
          field: 'AssessmentEnergy',
          flex: 3,
          resizable: true,
          sortable: true,
          filter: true,
          suppressMovable: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: EnergyCellRenderer
        }
      ]
    },
    {
      headerName: 'Compare',
      headerGroupComponent: CustomerHeader,
      children: [
        {
          headerName: 'Emissions, CO\u2082e %',
          field: 'Emission',
          flex: 3,
          resizable: true,
          sortable: true,
          filter: true,
          suppressMovable: true,
          sort: 'asc',
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: IconCellRenderer,
          comparator: columnComparator,
          valueFormatter: (params) => params.value
        },
        {
          headerName: 'Energy %',
          field: 'Energy',
          flex: 3,
          resizable: true,
          sortable: true,
          suppressMovable: true,
          filter: true,
          cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
          cellRenderer: IconCellRenderer,
          comparator: columnComparator,
          valueFormatter: (params) => params.value
        }
      ]
    }
  ];
};
