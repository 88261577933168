import React from 'react';

import { EmissionsProvider } from '../../contexts/Emissions/EmissionsContext';
import { CompanyTotalsProvider } from '../../contexts/CompanyTotalsContext/CompanyTotalsContext';
import LineChart from '../../components/Charts/LineChart';
import EmissionsContent from '../../components/EmissionsContent/EmissionsContent';
import EmissionsFilter from '../../components/Filters/EmissionsFilter';
import { AccountsFilterContextProvider } from '../../contexts/FilterContexts/AccountsFilterContext';
import { ProvidersFilterContextProvider } from '../../contexts/FilterContexts/ProvidersFilterContext';
import { CategoriesFilterContextProvider } from '../../contexts/FilterContexts/CategoriesFilterContext';
import { ServicesFilterContextProvider } from '../../contexts/FilterContexts/ServicesFilterContext';
import { DateFilterContextProvider } from '../../contexts/FilterContexts/DateFilterContext';

function Emissions() {
  return (
    <ProvidersFilterContextProvider>
      <AccountsFilterContextProvider>
        <ServicesFilterContextProvider>
          <CategoriesFilterContextProvider>
            <DateFilterContextProvider>
              <CompanyTotalsProvider>
                <EmissionsProvider>
                  <EmissionsContent>
                    <EmissionsFilter />
                    <LineChart />
                  </EmissionsContent>
                </EmissionsProvider>
              </CompanyTotalsProvider>
            </DateFilterContextProvider>
          </CategoriesFilterContextProvider>
        </ServicesFilterContextProvider>
      </AccountsFilterContextProvider>
    </ProvidersFilterContextProvider>
  );
}

export default Emissions;
