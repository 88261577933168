import React, { useContext } from 'react';
import './LoadingModal.css';
import { TdsModal, TdsSpinner } from '@scania/tegel-react';
import LoadingContext from '../../contexts/common/LoadingContext';

function Toggle(props) {
  return props.show ? props.children : <></>;
}

function LoadingModal() {
  const loading = useContext(LoadingContext);
  return (
    <Toggle show={loading.activities.length > 0}>
      <TdsModal
        header={'Please wait...'}
        show={true}
        closable={false}
        actions-position={'static'}
        size={'xs'}
        prevent={true}>
        <div slot={'body'} className={'tds-container'}>
          <TdsSpinner
            className={'position-absolute'}
            style={{ height: '8rem', display: 'grid', placeItems: 'center' }}
            variant="standard"
            size={'lg'}
          />
        </div>
      </TdsModal>
    </Toggle>
  );
}

export default LoadingModal;
