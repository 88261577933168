import React from 'react';
import config from '../../config';
import { TdsBlock } from '@scania/tegel-react';
import ApiDescriptionContainer from './molecules/ApiDescriptionContainer';
import ApiDescriptionSection from './molecules/ApiDescriptionSection';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/src/styles/hljs';
import { ApiPathParameterContainer, LiWithTitle } from './molecules/LiWithTitle';

const exampleResponse = {
  data: {
    id: 'user.one@yourcompany.eu',
    type: 'users',
    attributes: {
      Email: 'user.one@yourcompany.eu',
      CustomerId: 'YourReferenceId',
      ViewAccounts: [],
      EditAccountAlias: [],
      Groups: [],
      IsAdmin: false,
      IsGroup: false
    }
  }
};

function DevelopersAccessApiGetUserPermissions() {
  return (
    <>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <ApiDescriptionContainer
          method={'get'}
          path={'/v1/auth/customers/'}
          description={
            'This endpoint returns information of a specific user under a specific customer. The data returned primarily contains permissions related information.'
          }>
          <ApiDescriptionSection header={'Path Parameters'}>
            <ApiPathParameterContainer>
              <LiWithTitle title={'customer_id'}>
                The id of the customer where to lookup the specific user.
              </LiWithTitle>
              <LiWithTitle title={'user_id'}>
                The id of the user to lookup, usually this is the users e-mail address.
              </LiWithTitle>
            </ApiPathParameterContainer>
          </ApiDescriptionSection>
        </ApiDescriptionContainer>
      </div>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <TdsBlock>
          <h2>Example</h2>
          <h3>Request</h3>
          <SyntaxHighlighter language="sh" style={docco} wrapLongLines>
            {"curl '" +
              config.API_GATEWAY_AUTH_URL +
              '/v1/auth/customers/<customer_id>/users/<user_id>\' -X GET -H "Authorization: Basic $API_KEY"'}
          </SyntaxHighlighter>

          <h3>Response</h3>
          <h4>Status Codes</h4>
          <code>
            200 <span className="code-general-divider">|</span> 400{' '}
            <span className="code-general-divider">|</span> 401
          </code>
          <h4>
            Payload Data <span style={{ fontSize: '0.75rem' }}>(200)</span>
          </h4>
          <SyntaxHighlighter language="json" style={docco} wrapLongLines>
            {JSON.stringify(exampleResponse, null, 2)}
          </SyntaxHighlighter>
        </TdsBlock>
      </div>
    </>
  );
}

export default DevelopersAccessApiGetUserPermissions;
