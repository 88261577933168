import React from 'react';
import Permissions from '../../components/Auth/Permissions';
import { CustomerUsersProvider } from '../../contexts/Auth/CustomerUsersContext';
import { UserPermissionsProvider } from '../../contexts/Permissions/UserPermissionsContext';
import MobileViewScreen from '../../UI/molecules/MobileViewScreen/MobileviewScreen';

function ManagePermissions() {
  return (
    <>
      <CustomerUsersProvider>
        <UserPermissionsProvider>
          <Permissions />
        </UserPermissionsProvider>
      </CustomerUsersProvider>
      <div className="settings-container-small-screen tds-hide-md tds-show-xs">
        <MobileViewScreen />
      </div>
    </>
  );
}

export default ManagePermissions;
