import React from 'react';
import TeX from '@matejmazur/react-katex';

function MethodologyScience() {
  return (
    <React.Fragment>
      <h2>The (Simplified) Science of Energy</h2>
      <p className="tds-body-01">
        In order to understand our calculations it is necessary to understand some basic science
        about energy first. For deeper knowledge, please consult the internet or your old school
        books ;-)
      </p>
      <div>
        <h4>Energy (E)</h4>
        <p className="tds-body-01">
          Energy is the property of the ability of work. The SI unit for this is the Joule, however
          there are other units. The kilowatt-hour (kWh) is a unit is very common unit of energy
          that is commonly found in billing and carbon-related emissions reports. It is the amount
          of energy converted from a 1 kilowatt system over the duration of one hour.
        </p>
        <p className="tds-body-01">
          <TeX math="1 \text{ kWh} = 1000 \text{ Wh} = 3600 \text{ kJ}" />
        </p>
      </div>

      <div>
        <h4>Power (P)</h4>
        <p className="tds-body-01">
          Power is the rate of energy conversion over time. A common unit of power is the watt (W).
          1 watt is the conversion of 1 joule (J) of energy per second.
        </p>
        <p className="tds-body-01">
          <TeX math="1 \text{ kW} = 1000 \text{ W}" />
        </p>
      </div>

      <div>
        <h4>Power Usage Effectiveness (PUE)</h4>
        <p className="tds-body-01">
          Power Usage Effectiveness (PUE) is a way of describing the efficiency of the physical data
          center:
        </p>
        <p className="tds-body-01">
          <TeX math="\text{PUE} = \frac{\textit{Total energy usage}}{\textit{IT equipment energy}}" />
        </p>
      </div>
      <div>
        <h4>Carbon Intensity (CI)</h4>
        <p className="tds-body-01">
          Carbon Intensity (<b>CI</b>) is the measure of carbon equivalents generated per unit of
          energy consumed, normally in <b>kg per kWh</b>. Therefore, to calculate the carbon
          emissions we use the following formula:
        </p>
        <p className="tds-body-01">
          <TeX math={'\\text{Carbon}=\\text{E} \\times \\text{CI}'} />
        </p>
        <h4>Assumptions and Constants</h4>
        <p className="tds-body-01">
          The Carbon Intensity for a particular source of energy varies from low (wind, solar) to
          high (coal, natural gas). As any one point in time the carbon intensity for a region
          (county, country, continent, world) is determined from the mix of the region&apos;s energy
          sources. Calculating the exact value for Carbon Intensity is very difficult and we will
          gladly leave this to the experts, in addition to this we are uncertain if that level of
          detail is even necessary in guiding businesses in their transformation.
        </p>
        <p className="tds-body-01">
          To gain some insight into how the carbon intensity values for each country can vary,
          please have a look at the data supplied by the{' '}
          <a
            href={
              'https://www.eea.europa.eu/data-and-maps/daviz/co2-emission-intensity-6/download.table'
            }>
            European Environmental Agency.
          </a>
        </p>
      </div>
    </React.Fragment>
  );
}

export default MethodologyScience;
