import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  useEffect
} from 'react';
import DetailedEmissionsContext from '../../contexts/Emissions/DetailedEmissionsContext';
import { getSiPrefixedNumber } from '../../utilities/SiPrefix';

import logger from '../../logger';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './DetailService.css';
import useLocalStorage from 'use-local-storage';
import AccountsFilterContext from '../../contexts/FilterContexts/AccountsFilterContext';
import LoadingContent from '../common/LoadingContent';
import ProvidersFilterContext from '../../contexts/FilterContexts/ProvidersFilterContext';

const DetailService = forwardRef(function DetailService(props, ref) {
  const detailedEmissions = useContext(DetailedEmissionsContext);
  const accountsFilterContext = useContext(AccountsFilterContext);
  const providersFilterContext = useContext(ProvidersFilterContext);

  const [filteredEmissionsSummary, setFilteredEmissionsSummary] = useState(0);
  const [filteredEnergySummary, setFilteredEnergySummary] = useState(0);
  const [blurSensitive] = useLocalStorage('blur', false);

  const gridRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      handleExportOnClick() {
        gridRef.current.api.exportDataAsCsv({
          // Add callback to customize the cell values
          processCellCallback: (cell) => {
            if (cell.column.colDef.field === 'Emissions' || cell.column.colDef.field === 'Energy') {
              return cell.value ? cell.value / 1000 : 0; // Dividing by 1000 as the export will have the units in kg and kWh
            }

            return cell.value;
          },
          // Add callback to customize the cell headers
          processHeaderCallback: (header) => {
            if (header.column.colDef.field === 'Emissions') {
              return 'Emissions (kg)';
            }
            if (header.column.colDef.field === 'Energy') {
              return 'Energy (kWh)';
            }

            return header.column.colDef.headerName;
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    props.setGridReady(false);
  }, []);

  const getDefaultColumnDef = () => {
    return {
      lockPinned: true
    };
  };

  const getColumnDefs = () => {
    return [
      {
        headerName: props.accountTypeLabel + ' Id',
        field: 'Account',
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: { filter: blurSensitive ? 'blur(5px)' : 'none' },
        cellRenderer: (params) => {
          let account = accountsFilterContext.getItem(params.value);
          return (
            <span className="detailservice-cell-text-account-base">
              <div className="detailservice-cell-text-account-alias">{account.label}</div>
              <div className="detailservice-cell-text-account-id">({account.id})</div>
            </span>
          );
        }
      },
      {
        headerName: 'Resource Id',
        field: 'Resource',
        flex: 2,
        resizable: true,
        sortable: true,
        filter: true,
        valueFormatter: resourceIdFormatter,
        cellStyle: { filter: blurSensitive ? 'blur(5px)' : 'none' }
      },
      {
        headerName: 'Service Name',
        field: 'Service',
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true
      },
      {
        headerName: 'Region',
        field: 'Region',
        flex: 1,
        resizable: true,
        sortable: true,
        filter: true
      },
      {
        headerName: 'Emissions',
        field: 'Emissions',
        width: 150,
        resizable: true,
        sortable: true,
        sort: 'desc',
        unSortIcon: true,
        lockPosition: 'right',
        valueFormatter: emissionsFormatter
      },
      {
        headerName: 'Energy',
        field: 'Energy',
        width: 150,
        sortable: true,
        unSortIcon: true,
        lockPosition: 'right',
        valueFormatter: energyFormatter
      }
    ];
  };

  const onGridReady = (grid) => {
    logger(grid);
    props.setGridReady(true);
  };

  const updateSummary = (grid) => {
    var filteredEmissionsSum = 0;
    var filteredEnergySum = 0;

    grid.api.forEachNodeAfterFilterAndSort((rowNode) => {
      filteredEmissionsSum += grid.api.getValue('Emissions', rowNode);
      filteredEnergySum += grid.api.getValue('Energy', rowNode);
    });

    setFilteredEmissionsSummary(filteredEmissionsSum);
    setFilteredEnergySummary(filteredEnergySum);
  };

  const resourceIdFormatter = (params) => {
    if (params.value.substr(0, 3) == 'arn') {
      let components = params.value.split(':');
      if (components.length < 5) {
        return params.value;
      } else if (components.length == 6) {
        const [, , , , , ...resource] = components;
        return resource.join(':');
      } else {
        const [, , , , , , ...resource] = components;
        return resource.join(':');
      }
    } else if (params.value.substr(0, 15) == '/subscriptions/') {
      let components = params.value.split('/');
      return components.slice(-2).join('/');
    }
    return params.value;
  };

  const getSiPrefixedNumberGrams = (value) => getSiPrefixedNumber(value) + 'g';
  const getSiPrefixedNumberWattHours = (value) => getSiPrefixedNumber(value) + 'Wh';

  const emissionsFormatter = (params) => {
    return getSiPrefixedNumberGrams(params.value);
  };

  const energyFormatter = (params) => {
    return getSiPrefixedNumberWattHours(params.value);
  };

  const getNumberOfSelectedAccounts = () => {
    let accounts = accountsFilterContext.getSelectedItems();

    if (accounts instanceof Array) {
      return accounts.length;
    } else if (accounts instanceof Object) {
      return 1;
    } else {
      return 0;
    }
  };

  const getAccountTypeLabel = (provider, numberofAccounts) => {
    switch (provider) {
      case 'AZURE':
        return numberofAccounts !== 1 ? 'Subscriptions' : 'Subscription';
      case 'AWS':
        return numberofAccounts !== 1 ? 'Accounts' : 'Account';
      default:
        return numberofAccounts !== 1 ? 'Resource Boundaries' : 'Resource Boundary';
    }
  };

  const getLoadingOverlayComponent = useMemo(() => {
    return LoadingContent;
  }, []);
  const getLoadingOverlayComponentParams = useMemo(() => {
    return {
      loadingHeading: 'Retrieving data',
      loadingMessage: 'PLEASE HOLD'
    };
  }, []);

  const getNoRowsOverlayComponent = useMemo(() => {
    return LoadingContent;
  }, []);
  const getNoRowsOverlayComponentParams = useMemo(() => {
    return {
      loadingHeading: 'No data to display',
      loadingMessage: 'Try adjusting your filters',
      flashing: false,
      animate: false
    };
  }, []);
  const getUnsupportedOverlayComponentParams = useMemo(() => {
    return {
      loadingHeading: 'Cannot display Emissions Details',
      loadingMessage: `Max ${detailedEmissions.maxNoAccounts} ${getAccountTypeLabel(
        providersFilterContext.getSelectedItem().id,
        getNumberOfSelectedAccounts()
      ).toLowerCase()} supported`,
      flashing: false,
      animate: false
    };
  }, []);

  return (
    <>
      <div className="detail-service">
        {/* <div className=""> */}
        <div className="tds-u-h-100">
          <div className="h-100 tds-u-w-100">
            <div className="ag-theme-alpine detailservice-grid-container">
              <AgGridReact
                ref={gridRef}
                columnDefs={getColumnDefs()}
                defaultColDef={getDefaultColumnDef()}
                rowData={detailedEmissions.details}
                suppressDragLeaveHidesColumns={true}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                alwaysShowVerticalScroll={true}
                onGridReady={onGridReady}
                onFilterChanged={updateSummary}
                onFirstDataRendered={updateSummary}
                loadingOverlayComponent={getLoadingOverlayComponent}
                loadingOverlayComponentParams={getLoadingOverlayComponentParams}
                noRowsOverlayComponent={getNoRowsOverlayComponent}
                noRowsOverlayComponentParams={
                  detailedEmissions.isTooManyAccounts
                    ? getUnsupportedOverlayComponentParams
                    : getNoRowsOverlayComponentParams
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'detailservice-footer-container d-flex justify-content-end'}>
        <div className="detailservice-footer-column">
          {getSiPrefixedNumberGrams(filteredEmissionsSummary)}{' '}
          <span style={{ fontSize: '0.625rem' }}>(excl. embodied)</span>
        </div>
        <div className="detailservice-footer-column">
          {getSiPrefixedNumberWattHours(filteredEnergySummary)}
        </div>
      </div>
      {/* </div> */}
    </>
  );
});

export default DetailService;
