import React from 'react';

function MethodologyLambda() {
  return (
    <React.Fragment>
      <h2>Notes on AWS Lambda</h2>
      <p className="tds-body-01">
        AWS Lambda calculations on usage data, which is measured in Gigabyte-seconds (GB-second).
        Lambda pricing is proportional to execution time and allocated memory, and the usage amount
        is a measurement of this.
      </p>
      <p className="tds-body-01">
        Example: A usage of 1 GB-second is equivalent to a 1GB Lambda function with an execution
        time of 1 second, or a 2 GB Lambda function executing for 0.5 seconds.
      </p>
      <p className="tds-body-01">
        According to the{' '}
        <a
          href={
            'https://docs.aws.amazon.com/lambda/latest/dg/configuration-function-common.html#configuration-memory-console'
          }>
          AWS Lambda documentation
        </a>{' '}
        a Lambda function with 1.75GB memory is allocated 1 vCPU and is proportional to the memory
        allocated.
      </p>
    </React.Fragment>
  );
}

export default MethodologyLambda;
