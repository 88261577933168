import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import DateSelection from '../../molecules/DateSelection/DateSelection';
import './DatePickerCalendar.css';
import { subDays } from 'date-fns';
import { TdsButton } from '@scania/tegel-react';

const DatePickerCalendar = (props) => {
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      format(props.selectedDates[0].startDate, 'dd-MM-yyyy') ===
      format(props.selectedDates[0].endDate, 'dd-MM-yyyy')
    ) {
      alert('Please select a range');
    } else {
      props.handleDateFilterChange(props.selectedDates);
      props.closeDropdown();
    }
    setDisabled(true);
  };
  return (
    <div>
      {
        <div>
          <div className="d-flex date-picker-calendar-container">
            <div className="d-flex flex-column">
              <div className="date-picker-calendar-fixed-heading-text">
                <div>QUICK SELECTIONS</div>
                <div className="date-picker-calendar-fixed-heading-divider" />
              </div>
              <div className="px-2 date-picker-calendar-fixed-date-container">
                <DateSelection
                  id={props.id}
                  selectedDates={props.selectedDates}
                  setSelectedDates={props.setSelectedDates}
                  setDisable={setDisabled}
                  days={7}
                  text={props.id === 'baseline' ? 'Last week' : 'One week'}
                />
                <DateSelection
                  id={props.id}
                  selectedDates={props.selectedDates}
                  setSelectedDates={props.setSelectedDates}
                  setDisable={setDisabled}
                  days={30}
                  text={props.id === 'baseline' ? 'Last month' : 'One month'}
                />
                <DateSelection
                  id={props.id}
                  selectedDates={props.selectedDates}
                  setSelectedDates={props.setSelectedDates}
                  setDisable={setDisabled}
                  days={365}
                  text={props.id === 'baseline' ? 'Last year' : 'One year'}
                />
              </div>
            </div>
            <DateRange
              onChange={(item) => {
                props.setSelectedDates([item.selection]);
                setDisabled(false);
              }}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={props.selectedDates}
              months={2}
              maxDate={subDays(new Date(), 1)}
              direction="horizontal"
              className="date-picker-calendar-range"
              rangeColors={[
                'var(--gaia-color-primary-500)',
                'var(--gaia-color-primary-800)',
                'var(--gaia-color-primary-300)'
              ]}
            />
            <DateRange
              onChange={(item) => {
                props.setSelectedDates([item.selection]);
                setDisabled(false);
              }}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={props.selectedDates}
              months={1}
              maxDate={subDays(new Date(), 1)}
              direction="horizontal"
              className="date-picker-calendar-range-mobile"
              rangeColors={[
                'var(--gaia-color-primary-500)',
                'var(--gaia-color-primary-800)',
                'var(--gaia-color-primary-300)'
              ]}
            />
          </div>
          <div className="d-flex justify-content-end gap-2 mx-2 rounded">
            <TdsButton
              className="rounded date-picker-calendar-container-button"
              onClick={handleSubmit}
              disabled={disabled}
              size="sm"
              text="Apply Filter"></TdsButton>
          </div>
        </div>
      }
    </div>
  );
};

export default DatePickerCalendar;
