import React from 'react';

// import './RecommendationsRightsizing.css';

function RecommendationsRightsizing() {
  return (
    <React.Fragment>
      <p className={'tds-body-01'}>
        <div>
          <h2>Rightsizing</h2>
          Rightsizing is the process of changing the capacity of a resource to meet system
          requirements more exactly. Use no more or less that you need. It will help make your
          system environmentally efficient by reducing the amount of energy it consumes. A nice
          side-effect of rightsizing is that operational cost will reduce because less powerful
          computers typically cost less.
          <ul>
            <li>
              <h6>
                <b>Comparison</b>
              </h6>
              Imagine you are a logistics company that transports toilet paper to distribution hubs
              and supermarkets. As the nation&apos;s need for toilet paper varies you will need to
              change the rate of delivery. One way of doing this is to change the size of the trucks
              making the delivery. Larger trucks can delivery more, smaller trucks less. The
              environmental impact of the truck is related to its size so it makes sense that you
              need a truck that meets the requirements of the delivery. Too large and your truck
              will have an unnecessary environmental impact (diesel), too small and you won&apos;t
              be able to meet demands. The same goes for the IT industry - too much computing power
              and it is wasting energy (electricity), too little and it won&apos;t meet the demands.
              Choosing the right size of resources for the job is called rightsizing.
            </li>
            <li>
              <h6>
                <b>Handling variations in capacity</b>
              </h6>
              Let&apos;s say that the need for toilet paper increases for a short period of time.
              Your fleet has already been rightsized to meet a specific capacity, so what do you do?
              You use more trucks! The opposite applies for a reduction in demand for toilet paper -
              you reduce the number of trucks making the deliveries. This is a form of rightsizing
              called scaling. In the IT industry we refer to this as auto-scaling - automatically
              adjusting the number of resources available to do the job.
            </li>
          </ul>
          A good motto that you can remember related to Rightsizing is:
          <h5>
            &ldquo;&nbsp;
            <span className="recommendations-rightsizing-text-quote">
              The <b>greenest energy</b> is the energy <b>you don&apos;t use</b>
              !&nbsp;&rdquo;
            </span>
          </h5>
        </div>
      </p>
    </React.Fragment>
  );
}

export default RecommendationsRightsizing;
