import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import { Link, Route, Routes } from 'react-router-dom';
import React from 'react';
import DevelopersIntroduction from './DevelopersIntroduction';
import DevelopersAuthentication from './DevelopersAuthentication';
import DevelopersDataApi from './DevelopersDataApi';
import DevelopersAccessApi from './DevelopersAccessApi';

function Developers() {
  return (
    <div>
      <TdsNavigationTabs defaultSelectedIndex={0}>
        <TdsNavigationTab>
          <Link to={''}>Introduction</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'authentication'}>Authentication</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'data'}>Data API</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'access'}>Access API</Link>
        </TdsNavigationTab>
      </TdsNavigationTabs>

      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <Routes>
              <Route index element={<DevelopersIntroduction />} />
              <Route path={'authentication'} element={<DevelopersAuthentication />} />
              <Route path={'data'} element={<DevelopersDataApi />} />
              <Route path={'access'} element={<DevelopersAccessApi />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Developers;
