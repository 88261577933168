import React, { useContext, useEffect, useState } from 'react';
import './CheckboxItem.css';
import { TdsCheckbox, TdsIcon } from '@scania/tegel-react';

function CheckboxItem(props) {
  const getSafeLabel = () => context?.getItem(props.id)?.label ?? '-';
  const getSafeSubLabel = () => context?.getItem(props.id)?.subLabel ?? null;
  const getSafeSelection = () => context?.getItem(props.id)?.selected ?? false;
  const getSafeFavourite = () => context?.getItem(props.id)?.favourite ?? false;
  const getSafeDisabled = () => context?.getItem(props.id)?.disabled ?? false;
  const getSafeBlur = () => context?.getItem(props.id)?.blur ?? false;

  const context = useContext(props.itemsContextType);

  const [label, setLabel] = useState(context?.getItem(props.id)?.label);
  const [subLabel, setSubLabel] = useState(getSafeSubLabel());
  const [selected, setSelected] = useState(getSafeSelection());
  const [favourite, setFavourite] = useState(getSafeFavourite());
  const [disabled, setDisabled] = useState(getSafeDisabled());
  const [blur, setBlur] = useState(getSafeBlur());
  const checkAreaClicked = () => {
    // Send selection down to event handler in props (parent)
    props.onSelectionChanged(props.id, !selected);
  };

  const itemControlClicked = (item, event) => {
    // Prevent bubbling of event such as selection of the item
    event.stopPropagation();

    // Send click down to event handler in item props (parent)
    item.props.onClick(props.id);
  };

  const favouritesClicked = (event) => {
    // Prevent bubbling of event such as selection of the item
    event.stopPropagation();

    // Set item as favourite in context
    context.toggleFavouriteItem(props.id);
    setFavourite(!favourite);
  };

  const renderAdditionalItemControls = () => {
    const resolvedItemControls = [];

    // Populate any additional item controls that has been provided
    if (props.additionalItemControls) {
      props.additionalItemControls.forEach((item) => {
        if (item.props.shallBeDisplayed(context?.getItem(props.id))) {
          let key = props.keyPrefix + props.id + item.key;
          resolvedItemControls.push(
            <span key={key} onClick={itemControlClicked.bind(this, item)} className="tds-u-ml1">
              {item.props.children}
            </span>
          );
        }
      });
    }

    // Populate favourite button if enabled
    if (props.favouritesEnabled) {
      let key = props.keyPrefix + props.id + 'FAVOURITE';
      resolvedItemControls.push(
        <span key={key} onClick={favouritesClicked.bind(this)}>
          {favourite ? (
            <TdsIcon slot={'icon'} size={'20px'} name={'heart'} />
          ) : (
            <TdsIcon slot={'icon'} size={'20px'} name={'heart_inactive'} />
          )}
        </span>
      );
    }

    return resolvedItemControls;
  };

  useEffect(() => {
    setLabel(getSafeLabel());
    setSubLabel(getSafeSubLabel());
    setSelected(getSafeSelection());
    setFavourite(getSafeFavourite());
    setDisabled(getSafeDisabled());
    setBlur(getSafeBlur());
  }, [context?.updated]);

  return (
    <div
      className="tds-u-flex tds-u-p1 tds-u-align-center checkbox-item-base"
      onClick={checkAreaClicked.bind(this)}>
      <TdsCheckbox
        type="checkbox"
        onTdsChange={checkAreaClicked.bind(this)}
        checked={selected}
        disabled={disabled}
        className={'tds-u-pl1'}></TdsCheckbox>

      <span
        className={
          'tds-u-flex tds-u-flex-dir-col tds-u-justify-center tds-u-w-100 tds-u-pl1 tds-u-pr1'
        }>
        <div className="tds-u-flex">
          <span
            className={
              blur
                ? 'tds-u-m-auto flex-grow-1 checkbox-item-text-overflow blur'
                : 'tds-u-m-auto flex-grow-1 checkbox-item-text-overflow'
            }>
            {label}
          </span>
          <span className="justify-content-end">
            <span className="tds-u-flex tds-u-gap1 ">{renderAdditionalItemControls()}</span>
          </span>
        </div>

        <div
          className={
            blur
              ? 'tds-detail-05 checkbox-item-text-overflow blur'
              : 'tds-detail-05 checkbox-item-text-overflow'
          }>
          {subLabel}
        </div>
      </span>
    </div>
  );
}

// Set default properties
// CheckboxItem.defaultProps = {
//   itemsContextType: null,
//   keyPrefix: null,
//   id: null,
//   displayBold: false,
//   favouritesEnabled: true,
//   additionalItemControls: null
// };

export default CheckboxItem;
