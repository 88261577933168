import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import TrendsFilterContext from '../../../contexts/Trends/TrendsFilterContext';
import useLocalStorage from 'use-local-storage';
import { format } from 'date-fns';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columnOptions } from './TrendsOptions';
import './TrendsTable.css';
import CustomerAccountsContext from '../../../contexts/Customer/CustomerAccountsContext';
import LoadingContent from '../../../components/common/LoadingContent';
import FileCSV01Icon from '../../atoms/SvgIcons/FileCSV01Icon';
import * as PropTypes from 'prop-types';

function TrendsDateSummary(props) {
  const trendsFilterContext = useContext(TrendsFilterContext);
  return (
    <div className="tds-u-flex tds-u-flex-wrap tds-u-gap3">
      <p>
        <b>Baseline period : </b>
        {format(trendsFilterContext.baseLineDate[0].startDate, 'd MMM yyyy')}-{' '}
        {format(trendsFilterContext.baseLineDate[0].endDate, 'd MMM yyyy')}
      </p>
      <p className="">
        <b>Assessment period : </b>
        {format(trendsFilterContext.assessmentDate[0].startDate, 'd MMM yyyy')}-{' '}
        {format(trendsFilterContext.assessmentDate[0].endDate, 'd MMM yyyy')}
      </p>

      <span title="Export data as CSV" className="trends-table-export-icon" onClick={props.onClick}>
        <FileCSV01Icon backgroundColor="var(--cet-color-secondary-dark)" />
      </span>
    </div>
  );
}

TrendsDateSummary.propTypes = {
  trendsFilterContext: PropTypes.any,
  onClick: PropTypes.func
};
const TrendsTable = (props) => {
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const [blurSensitive] = useLocalStorage('blur', false);
  const gridRefTrends = useRef(null);
  const [rowData, setRowData] = useState(undefined);
  const gridRef = useRef(null);
  const [gridHeight, setGridHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setGridHeight(window.innerHeight - gridRef.current.offsetTop);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const gridStyle = { width: '100%', height: `${gridHeight}px` };

  // Parse row data to fit data model for Grid
  useEffect(() => {
    setRowData(
      props.trendsAverageData?.Data.map((item) => {
        return {
          Provider: props.trendsAverageData.Provider,
          Account: getAccountInfo(props.trendsAverageData.Provider, item.AccountId),
          Alias: getAccountInfo(props.trendsAverageData.Provider, item.AccountId).Alias,
          BaselineEmissions: item.averageCarbonBaseline ? item.averageCarbonBaseline : 0,
          BaselineEnergy: item.averageEnergyBaseline ? item.averageEnergyBaseline : 0,
          AssessmentEmissions: item.averageCarbonAssessment ? item.averageCarbonAssessment : 0,
          AssessmentEnergy: item.averageEnergyAssessment ? item.averageEnergyAssessment : 0,
          Emission: {
            increase: Trend(item.averageCarbonBaseline, item.averageCarbonAssessment),
            value: item.averageCarbonBaseline
              ? (
                  ((item.averageCarbonAssessment - item.averageCarbonBaseline) * 100) /
                  item.averageCarbonBaseline
                ).toFixed(2)
              : null
          },
          Energy: {
            increase: Trend(item.averageEnergyBaseline, item.averageEnergyAssessment),
            value: item.averageEnergyBaseline
              ? (
                  ((item.averageEnergyAssessment - item.averageEnergyBaseline) * 100) /
                  item.averageEnergyBaseline
                ).toFixed(2)
              : null
          }
        };
      })
    );
  }, [props.trendsAverageData]);

  useEffect(() => {
    if (props.isLoading) {
      gridRefTrends.current.api?.showLoadingOverlay();
    }
  }, [props.isLoading]);

  //Adding required units for the emissions and energy values
  const onFileExport = () => {
    gridRefTrends.current.api.exportDataAsCsv({
      // Add a callback to customise the cell values
      processCellCallback: function (cell) {
        var cellVal = cell.value;
        if (cell.column.colDef.field === 'Emission' || cell.column.colDef.field === 'Energy') {
          cellVal = cell.value.value !== null ? Math.round(cell.value.value) : '-';
        }
        if (
          cell.column.colDef.field === 'BaselineEmissions' ||
          cell.column.colDef.field === 'BaselineEnergy' ||
          cell.column.colDef.field === 'AssessmentEmissions' ||
          cell.column.colDef.field === 'AssessmentEnergy'
        ) {
          cellVal = cell.value ? cell.value / 1000 : '-'; //dividing by 1000 as the export will have the units in kg and kWh
        }
        if (cell.column.colDef.field === 'Account') {
          cellVal = cell.value.AccountId;
        }
        return cellVal;
      },
      // Add callback to customise the cell headers
      processHeaderCallback: function (header) {
        if (header.column.colDef.field === 'BaselineEmissions') {
          return 'Baseline Daily Average Emissions (kg)';
        }
        if (header.column.colDef.field === 'BaselineEnergy') {
          return 'Baseline Daily Average Energy (kWh)';
        }
        if (header.column.colDef.field === 'AssessmentEmissions') {
          return 'Assessment Daily Average Emissions (kg)';
        }
        if (header.column.colDef.field === 'AssessmentEnergy') {
          return 'Assessment Daily Average Energy(kWh)';
        }
        return header.column.colDef.headerName;
      },
      skipColumnGroupHeaders: true, //Remove showing group headers in export
      allColumns: true //show all the columns including hidden ones, like alias
    });
  };

  //checking the status of value, increased, decreased or equal
  const Trend = (averageValueBaseline, averageValueAssessment) => {
    if (averageValueBaseline && averageValueAssessment) {
      if (averageValueAssessment === averageValueBaseline) return 'equal';
      return averageValueAssessment > averageValueBaseline ? 'increase' : 'decrease';
    } else return null;
  };

  const getAccountInfo = (provider, accountId) => {
    if (customerAccountsContext.accounts && customerAccountsContext.accounts.length > 0) {
      const selectedAccount = customerAccountsContext.accounts.find(
        (item) => item.Provider === provider && item.AccountId === accountId
      );
      return { AccountId: accountId, Alias: selectedAccount.Alias };
    }
  };

  const getDefaultColumnDef = () => {
    return {
      lockPinned: true
    };
  };

  const columnDefs = columnOptions(props.trendsAverageData, blurSensitive);

  const getLoadingOverlayComponent = useMemo(() => {
    return LoadingContent;
  }, []);
  const getLoadingOverlayComponentParams = useMemo(() => {
    return {
      loadingHeading: 'Retrieving data',
      loadingMessage: 'PLEASE HOLD'
    };
  }, []);

  const getNoRowsOverlayComponent = useMemo(() => {
    return LoadingContent;
  }, []);
  const getNoRowsOverlayComponentParams = useMemo(() => {
    return {
      loadingHeading: 'No data to display',
      loadingMessage: 'Try adjusting your filters',
      flashing: false,
      animate: false
    };
  }, []);

  const onGridReady = (grid) => {
    // Show NoRowsOverlay if data is empty at start
    if (!rowData && !props.isLoading) {
      grid.api.hideOverlay();
      // Give some ms for LoadingOverlay to hide
      setTimeout(() => {
        grid.api.showNoRowsOverlay();
      }, 10);
    }
  };

  return (
    <>
      <TrendsDateSummary onClick={onFileExport} />
      <div className="ag-theme-alpine" style={gridStyle} ref={gridRef}>
        <AgGridReact
          ref={gridRefTrends}
          rowData={rowData}
          columnDefs={columnDefs}
          alwaysShowVerticalScroll={true}
          defaultColDef={getDefaultColumnDef()}
          onGridReady={onGridReady}
          loadingOverlayComponent={getLoadingOverlayComponent}
          loadingOverlayComponentParams={getLoadingOverlayComponentParams}
          noRowsOverlayComponent={getNoRowsOverlayComponent}
          noRowsOverlayComponentParams={getNoRowsOverlayComponentParams}
        />
      </div>
    </>
  );
};

export default TrendsTable;
