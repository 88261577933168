import React from 'react';
import config from '../../config';
import { TdsBlock } from '@scania/tegel-react';
import ApiDescriptionContainer from './molecules/ApiDescriptionContainer';
import ApiDescriptionSection from './molecules/ApiDescriptionSection';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/src/styles/hljs';
import DivTdsBody01 from '../../UI/atoms/Typography/DivTdsBody01';
import { ApiPathParameterContainer, LiWithTitle } from './molecules/LiWithTitle';

const exampleRequest = {
  data: {
    type: 'users',
    attributes: {
      CustomerId: '<customer_id>',
      Email: 'user.one@yourcompany.eu',
      ViewAccounts: ['ACCOUNT_ONE', 'ACCOUNT_TWO'],
      EditAccountAlias: ['ACCOUNT_ONE']
    }
  }
};

const exampleResponse = {
  data: {
    id: 'user.one@yourcompany.eu',
    type: 'users',
    attributes: {
      Email: 'user.one@yourcompany.eu',
      CustomerId: 'YourReferenceId',
      ViewAccounts: ['ACCOUNT_ONE', 'ACCOUNT_TWO'],
      EditAccountAlias: ['ACCOUNT_ONE'],
      Groups: [],
      IsAdmin: false,
      IsGroup: false
    }
  }
};

function DevelopersAccessApiPostUserPermissions() {
  return (
    <>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <ApiDescriptionContainer
          method={'post'}
          path={'/v1/auth/customers/{customer_id}/users'}
          description={
            'This endpoint creates a non-existing user and associates that user with the specified customer.'
          }>
          <ApiDescriptionSection header={'Path Parameters'}>
            <ApiPathParameterContainer>
              <LiWithTitle title={'customer_id'}>
                The id of the customer to work against.
              </LiWithTitle>
            </ApiPathParameterContainer>
          </ApiDescriptionSection>
          <ApiDescriptionSection header={'Request Body (as JSON object)'}>
            <ul>
              <LiWithTitle title={'data.type [REQUIRED]'}>
                Internal use, only allowed value is{' '}
                <b>
                  <code>users</code>
                </b>
                .
              </LiWithTitle>
              <LiWithTitle title={'data.attributes.CustomerId [REQUIRED]'}>
                The customer id to associate the user with, should be the same as specified in the
                Path Parameters.
              </LiWithTitle>
              <LiWithTitle title={'data.attributes.Email [REQUIRED]'}>
                The user id (email) that will be created.
              </LiWithTitle>
              <LiWithTitle title={'data.attributes.ViewAccounts'}>
                Specify (as a list of strings) all accounts/subscriptions that the user should be
                allowed to view.
                <br />
                Default value is{' '}
                <b>
                  <code>[]</code>
                </b>
                .
              </LiWithTitle>
              <LiWithTitle title={'data.attributes.EditAccountAlias'}>
                Specify (as a list of strings) all accounts/subscriptions that the user should be
                allowed to edit account alias on.
                <br />
                Default value is{' '}
                <b>
                  <code>[]</code>
                </b>
                .
              </LiWithTitle>
              <LiWithTitle title={'data.attributes.IsAdmin'}>
                Specifies if the user should be assigned as administrator or not, allowed values:{' '}
                <code>true | false</code>
                .
                <br />
                Default value is{' '}
                <b>
                  <code>false</code>
                </b>
                .
              </LiWithTitle>
            </ul>
          </ApiDescriptionSection>
          <ApiDescriptionSection header={'Required Permissions'}>
            <DivTdsBody01>
              Admin permissions for the specific customer is required in order to get a successful
              response.
            </DivTdsBody01>
          </ApiDescriptionSection>
        </ApiDescriptionContainer>
      </div>
      <div className={'tds-col-xs-12 tds-col-lg-6'}>
        <TdsBlock>
          <h2>Example</h2>
          <h3>Request</h3>
          <SyntaxHighlighter language="sh" style={docco} wrapLongLines>
            {"curl '" +
              config.API_GATEWAY_AUTH_URL +
              '/v1/auth/customers/YourReferenceId/users\' -X POST -H "Authorization: Basic $API_KEY" ' +
              `-d '${JSON.stringify(exampleRequest)}'`}
          </SyntaxHighlighter>

          <h3>Response</h3>
          <h4>Status Codes</h4>
          <code>200 | 400 | 401 | 409</code>
          <h4>Payload Data (200)</h4>
          <SyntaxHighlighter language="json" style={docco} wrapLongLines>
            {JSON.stringify(exampleResponse, null, 2)}
          </SyntaxHighlighter>
        </TdsBlock>
      </div>
    </>
  );
}

export default DevelopersAccessApiPostUserPermissions;
