import React, { useEffect, useContext, useState } from 'react';
import CategoriesFilterContext from '../../../contexts/FilterContexts/CategoriesFilterContext';
import FilterDropdown from '../../molecules/Filter/FilterDropdown';
import HandleOutsideClickAndEsc from '../../atoms/Handlers/HandleOutsideClickAndEsc';
import FilterDropdownButton from '../../molecules/Filter/FilterDropdownButton';

function CategoriesFilter({ controlId, filterId, handlers = [], closeOnClickOutside }) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const context = useContext(CategoriesFilterContext);

  useEffect(() => {
    // Fetch items once filterId has been set
    if (context.filterId) {
      context.fetchItems();
    }
  }, [context.filterId]);

  const onClickDropdown = () => setDisplayDropdown(!displayDropdown);
  const closeDropdown = () => setDisplayDropdown(false);

  return (
    <HandleOutsideClickAndEsc enabled={closeOnClickOutside} handler={closeDropdown}>
      <FilterDropdownButton
        itemTypeLabel="categories"
        onClickDropdownHandler={onClickDropdown}
        displayDropdown={displayDropdown}
      />
      <FilterDropdown
        controlId={controlId}
        filterId={filterId}
        handlers={[...handlers, closeDropdown]}
        displayed={displayDropdown}
        itemTypeLabel="CATEGORIES"
        itemsContextType={CategoriesFilterContext}
        selectAllEnabled={true}
      />
    </HandleOutsideClickAndEsc>
  );
}

// Set default properties
// CategoriesFilter.defaultProps = {
//   controlId: 'DEFAULT', // TODO: Autogenerate unique ID here
//   filterId: undefined,
//   handlers: [],
//   closeOnClickOutside: undefined
// };

export default CategoriesFilter;
