import React from 'react';
import { TdsButton } from '@scania/tegel-react';

const AwsIntegration = () => {
  return (
    <>
      <h2>Data Collection</h2>
      <p className={'tds-body-01'}>
        Our current method for collecting data from AWS is through an integration with an S3 bucket
        where Cost and Usage Report (CUR) data is available, but more integration options may become
        available in the future.
      </p>
      <p className={'tds-body-01'}>
        <a
          href="https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html"
          target="_blank"
          rel="noreferrer"
          className="underline">
          Cost and Usage Report
        </a>{' '}
        is a service available from AWS that easily can be activated in an account.
      </p>
      <p className={'tds-body-01'}>
        Creating the reports in a &quot;management account&quot; is preferred because it also
        includes data for all &quot;member accounts&quot; (
        <a
          href="https://docs.aws.amazon.com/organizations/latest/userguide/orgs_getting-started_concepts.html"
          target="_blank"
          rel="noreferrer"
          className="underline">
          AWS Organizations terminology and concepts
        </a>
        ).
      </p>
      <p className={'tds-body-01'}>
        Once we can access the CUR data and the integration has been setup, We perform scheduled
        queries using Athena. The Athena query extracts only the usage data from CUR; all cost
        information is ignored.
      </p>
      <p className={'tds-body-01'}>
        The integration setup comes with a small cost in your account since the CUR data is stored
        on S3 (
        <a
          href="https://aws.amazon.com/s3/pricing/"
          target="_blank"
          rel="noreferrer"
          className="underline">
          AWS S3 pricing
        </a>
        ).
      </p>
      <p className={'tds-body-01'}>
        Since CUR is a global service on AWS (it originates from the &quot;us-east-1&quot; region)
        we require the S3 bucket to be deployed there, this is also a cost-optimization measure to
        prevent regional transfer-costs.
      </p>
      <p className={'tds-body-01'}>
        NOTE: We can only calculate emissions on available CUR data, meaning that when the Cost and
        Usage Report are activated, it&apos;s from that time emissions can be calculated.
      </p>
      <h2>Infrastructure as Code</h2>
      <p className={'tds-body-01'}>
        We seldom do manual operations, and deploying infrastructure is no exception. Therefore, we
        offer our integration in the form of Infrastructure as Code for you to deploy on your side
        when integrating with us.
      </p>
      <p className={'tds-body-01'}>
        For AWS environments, all required resources are defined and bundled into this
        CloudFormation template:
      </p>
      <a href="downloads/cur.yml" download>
        <TdsButton
          className="tds-u-mb2"
          size="md"
          text="Download CloudFormation template"></TdsButton>
      </a>
    </>
  );
};

export default AwsIntegration;
