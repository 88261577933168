import React from 'react';
import './RecommendationsIntro.css';
// import RelocationIcon from '../../UI/atoms/SvgIcons/RelocationIcon';
// import RightsizingIcon from '../../UI/atoms/SvgIcons/RightsizingIcon';
// import ReArchitectureIcon from '../../UI/atoms/SvgIcons/ReArchitectureIcon';
import { TdsBlock } from '@scania/tegel-react';

function RecommendationsIntro() {
  return (
    <TdsBlock>
      <h2> Efficient IT Systems</h2>
      <p className={'tds-body-01'}>
        An IT system is environmentally efficient if it consumes just enough energy to run the
        system, but maximises its use of renewable and fossil-free energy. <br />
        <br />
        An environmentally efficient IT system can be achieved by following the three R&apos;s
        principle.
      </p>
    </TdsBlock>
  );
}

export default RecommendationsIntro;
