import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';

import CookieContext from '../../../../contexts/Cookies/CookieContext';
import logger from '../../../../logger';
import { TdsButton, TdsModal, TdsToggle } from '@scania/tegel-react';

const CET_COOKIE_APP = 'CETCookieConsentApp';
const CET_COOKIE_FUNCTIONAL = 'FunctionalCookieConsent';

function Toggle(props) {
  return props.show ? props.children : <></>;
}

const CookieSettingsModal = () => {
  const [show, setShow] = useState(false);
  const cookieContext = useContext(CookieContext);
  const [isFunctionalCookiesChecked, setIsFunctionalCookiesChecked] = useState(
    cookieContext.functionalCookies
  );
  const [cookies, setCookie] = useCookies([CET_COOKIE_APP, CET_COOKIE_FUNCTIONAL]);

  const handleClose = () => {
    setShow(false);
    setIsFunctionalCookiesChecked(cookieContext.functionalCookies);
  };
  const handleShow = () => setShow(true);
  logger(cookies);

  const handleSubmit = () => {
    setShow(false);
    cookieContext.setIsCookieConsent(true);
    setCookie(CET_COOKIE_APP, true, {
      path: '/',
      maxAge: 15768000
    });
    if (isFunctionalCookiesChecked) {
      setCookie(CET_COOKIE_FUNCTIONAL, true, {
        path: '/',
        maxAge: 15768000
      });
      cookieContext.setFunctionalCookies(true);
      // if user has already saved filters, then reload the page to get this implemented
      if (
        localStorage.getItem('SavedFilterSelections') ||
        localStorage.getItem('SavedFilterFavourites') ||
        localStorage.getItem('favouriteAccounts')
      ) {
        window.location.reload();
      }
    }
  };

  return (
    <>
      <TdsButton
        size="sm"
        variant="primary"
        onClick={handleShow}
        text="Cookie settings"></TdsButton>
      <Toggle show={show}>
        <TdsModal show={true} onTdsClose={handleClose} centered size={'xs'}>
          <div slot={'header'}>Cookie Settings</div>
          <div slot={'body'} className={'tds-u-flex tds-u-flex-dir-col tds-u-gap1'}>
            <div>
              <TdsToggle checked disabled>
                <div slot={'label'}>Essential Cookies</div>
              </TdsToggle>
            </div>
            <p>
              Necessary cookies are needed for the website to function and cannot be switched off in
              our systems. They are usually a consequence of you requesting services such as;
              setting your privacy preferences, logging in or filling in forms. These cookies do not
              store any personal data.
            </p>
            <div>
              <TdsToggle
                checked={isFunctionalCookiesChecked}
                onTdsToggle={(e) => {
                  setIsFunctionalCookiesChecked(e.detail.checked);
                }}>
                <div slot={'label'}>Functional Cookies</div>
              </TdsToggle>
            </div>
            <p>
              Functional cookies enable the website to provide enhanced functionality and
              personalisation.
            </p>
          </div>
          <div slot="actions" className={'tds-u-flex tds-u-gap1'}>
            <TdsButton
              variant="secondary"
              onClick={handleClose}
              size={'sm'}
              text={'Close'}></TdsButton>
            <TdsButton
              variant="primary"
              type={'submit'}
              onClick={handleSubmit}
              size={'sm'}
              text={'Save'}></TdsButton>
          </div>
        </TdsModal>
      </Toggle>
    </>
  );
};

export default CookieSettingsModal;
