import ProvidersFilter from '../../UI/organisms/Filters/ProvidersFilter';
import CategoriesFilter from '../../UI/organisms/Filters/CategoriesFilter';
import DateFilter from '../../UI/organisms/Filters/DateFilter';
import React, { useContext } from 'react';
import DateFilterContext from '../../contexts/FilterContexts/DateFilterContext';

function CompanyEmissionsFilter({
  filterId,
  selectedDates,
  setSelectedDates,
  handleDateFilterChange
}) {
  const dateFilterContext = useContext(DateFilterContext);
  return (
    <div>
      <div className="d-flex justify-content-start flex-wrap gap-2">
        <ProvidersFilter
          controlId={'ProvidersCompany'}
          filterId={filterId}
          closeOnClickOutside={true}
        />
        <CategoriesFilter filterId={filterId} closeOnClickOutside={true} />
        <DateFilter
          id="company"
          closeOnClickOutside={true}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          handleDateFilterChange={handleDateFilterChange}
          dateRange={dateFilterContext.dateRange}
        />
      </div>
    </div>
  );
}

export default CompanyEmissionsFilter;
