import React from 'react';

function MethodologyReferences() {
  return (
    <React.Fragment>
      <p className={'tds-body-01'}>
        <h2>References</h2>
        <ul>
          <li>
            <a href={'https://eta.lbl.gov/publications/united-states-data-center-energy'}>
              Average CPU usage (2016 US Data Center Energy Usage)
            </a>
          </li>
          <li>
            <a href={'https://codeascraft.com/2020/04/23/cloud-jewels-estimating-kwh-in-the-cloud'}>
              Estimating KWH in the cloud (Cloud Jewels)
            </a>
          </li>
          <li>
            <a
              href={
                'https://docs.aws.amazon.com/lambda/latest/dg/configuration-function-common.html#configuration-memory-console'
              }>
              Lambda vCPUs (AWS)
            </a>
          </li>
          <li>
            <a
              href={
                'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/burstable-performance-instances.html'
              }>
              Burstable Instances (AWS)
            </a>
          </li>
        </ul>

        <h2>Data Sources</h2>
        <p className="tds-body-01">
          Gaia uses the same carbon intensity values as Cloud Carbon Footprint. For more information
          and to see the current data, please follow the links below.
        </p>

        <ul>
          <li>
            <a href={'https://www.cloudcarbonfootprint.org/docs/methodology/#aws-2'}>
              Grid emissions factors (Cloud Carbon Footprint)
            </a>
          </li>
          <li>
            <a
              href={
                'https://www.eea.europa.eu/data-and-maps/daviz/co2-emission-intensity-6/download.table'
              }>
              CO2 emission intensity (European Environmental Agency)
            </a>
          </li>
          <li>
            <a
              href={
                'https://www.carbonfootprint.com/docs/2022_03_emissions_factors_sources_for_2021_electricity_v11.pdf'
              }>
              Carbon Footprint (Country specific electricity grid greenhouse gas emission factors)
            </a>
          </li>
          <li>
            <a href={'https://www.epa.gov/egrid/download-data'}>
              United States Environmental Protection Agency
            </a>
          </li>
        </ul>
      </p>
    </React.Fragment>
  );
}

export default MethodologyReferences;
