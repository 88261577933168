import React from 'react';

function ApiDescriptionSection({ header, children }) {
  return (
    <>
      <h3>{header}</h3>
      {children}
    </>
  );
}

export default ApiDescriptionSection;
