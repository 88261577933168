import React from 'react';
import { Link } from 'react-router-dom';
import ApiDescriptionContainer from './molecules/ApiDescriptionContainer';
import ApiDescriptionSection from './molecules/ApiDescriptionSection';
import { app_base_path_with_leading_slash } from '../../config';
import DivTdsBody01 from '../../UI/atoms/Typography/DivTdsBody01';
import { LiWithTitle } from './molecules/LiWithTitle';

const DevelopersDataApiDescription = () => {
  return (
    <ApiDescriptionContainer
      method={'post'}
      path={'/v1/data'}
      description={
        'This endpoint is for fetching emissions data for your accounts. To get the data you must do\n' +
        'a POST request. The request body is a JSON object with several attributes that define which\n' +
        'data is included in the results (filters), and one attribute that determines how the results\n' +
        'should be summarised (called GroupBy).'
      }>
      <ApiDescriptionSection header={'Request Body'}>
        <ul>
          <LiWithTitle title={'Period'}>
            The period attribute filters data between two points in time.
          </LiWithTitle>
          <LiWithTitle title={'user_id'}>
            The id of the user to lookup, usually this is the users e-mail address.
            <ul>
              <li>
                <b>Start</b>
                <br />
                The earliest timestamp of data that will be retrieved. Must be in the format:{' '}
                <b>
                  <code>YYYY-MM-DD</code>
                </b>
              </li>
              <li>
                <b>End</b>
                <br />
                The latest timestamp of data that will be retrieved (exclusive). Must be in the
                format:{' '}
                <b>
                  <code>YYYY-MM-DD</code>
                </b>
              </li>
            </ul>
          </LiWithTitle>
          <LiWithTitle title={'Customers'}>
            Only retrieve data for the customers specified in this list. If you do not know your
            customer ID then please look at the{' '}
            <Link to={app_base_path_with_leading_slash + '/developers/access'}>Access API</Link>{' '}
            first.
          </LiWithTitle>
          <LiWithTitle title={'Providers'}>
            The cloud providers to include when retrieving data. Valid values are{' '}
            <b>
              <code>AWS</code>
            </b>{' '}
            and{' '}
            <b>
              <code>AZURE</code>
            </b>
          </LiWithTitle>
          <LiWithTitle title={'Accounts'}>
            The accounts/subscriptions to include when retrieving data. If the attribute is not
            specified then the data will be restricted to the accounts you can access.
            <br />
            For company emissions, use{' '}
            <b>
              <code>ALL_COMPANY_ACCOUNTS</code>
            </b>{' '}
            as the only account value. This will retrieve all the company emission data for the
            accounts/subscriptions that have been integrated.
          </LiWithTitle>
          <LiWithTitle title={'Services'}>
            The services to include when retrieving data. If the attribute is not specified then all
            services will be included. Valid services are listed at the bottom of this section.
          </LiWithTitle>
          <LiWithTitle title={'PeriodGranularity'}>
            When the results are grouped by &quot;Period&quot;, this field determines the
            granularity of the time axis. Currently this value must be{' '}
            <b>
              <code>Daily</code>
            </b>
            .
          </LiWithTitle>
          <LiWithTitle title={'GroupBy'}>
            <DivTdsBody01>
              This optional attribute determines how the resulting data will be grouped, and is
              similar to the<b> group by</b> clause in SQL. It is a list of groups, and may be
              empty.
            </DivTdsBody01>
            <DivTdsBody01>
              The emissions matching your filters will be divided up amongst all the groups you
              specify.
            </DivTdsBody01>
            <DivTdsBody01>
              Valid values:{' '}
              <b>
                <code>PERIOD, ACCOUNT, REGION, RESOURCE, SERVICE </code>
              </b>
            </DivTdsBody01>
            <DivTdsBody01>
              Example 1:{' '}
              <b>
                <code>&quot;GroupBy&quot;: [&quot;PERIOD&quot;]</code>
              </b>{' '}
              results in daily emissions for all data that matches the filters.
            </DivTdsBody01>
            <DivTdsBody01>
              Example 2:{' '}
              <b>
                <code>&quot;GroupBy&quot;: [&quot;PERIOD&quot;, &quot;ACCOUNT&quot;]</code>
              </b>{' '}
              results in daily emissions for all data that matches the filters, but unlike example
              1, this will separate each daily total into the total for each account in the filter.
            </DivTdsBody01>
          </LiWithTitle>
        </ul>
      </ApiDescriptionSection>
      <ApiDescriptionSection header={'Limitations'}>
        <ul>
          <li>
            <DivTdsBody01>
              Grouping the data by resource is a very intensive operation and can return large
              quantities of data. For this reason, when grouping data by resource ID we restrict the
              number of accounts in the account filter to 10, and the number of days in the date
              period to 1.
            </DivTdsBody01>
          </li>
          <li>
            <DivTdsBody01>The Customers attribute may contain at most 1 item.</DivTdsBody01>
          </li>
          <li>
            <DivTdsBody01>The Providers attribute may contain at most 1 item.</DivTdsBody01>
          </li>
        </ul>
      </ApiDescriptionSection>
      <ApiDescriptionSection header={'Required Permissions'}>
        <DivTdsBody01>View permission on the resources that you are accessing.</DivTdsBody01>
      </ApiDescriptionSection>
      <ApiDescriptionSection header={'Valid Services'}>
        <div className="d-flex text-break">
          <div className="flex-grow-1">
            <b>AWS Services</b>
            <ul>
              <li>EC2</li>
              <li>Lambda</li>
              <li>S3</li>
              <li>DynamoDB</li>
              <li>CloudWatch</li>
              <li>RDS</li>
              <li>ApiGateway</li>
              <li>CloudFront</li>
              <li>ECS</li>
              <li>EKS</li>
              <li>Route53</li>
              <li>VPC</li>
              <li>ELB</li>
              <li>SimpleDB</li>
              <li>ECR</li>
              <li>EFS</li>
              <li>MSK</li>
              <li>Backup</li>
              <li>SecretsManager</li>
              <li>SystemsManager</li>
              <li>ElastiCache</li>
              <li>WAF</li>
              <li>CertificateManager</li>
              <li>SNS</li>
              <li>SQS</li>
            </ul>
          </div>
          <div className="flex-grow-1">
            <b>Azure Services</b>
            <ul>
              <li>Storage</li>
              <li>Functions</li>
              <li>AzureAppService</li>
              <li>VirtualMachines</li>
              <li>MySQLDatabase</li>
              <li>PostgreSQLDatabase</li>
              <li>CosmosDB</li>
              <li>AzureSQLDatabase</li>
              <li>AzureSQLManagedInstances</li>
              <li>APIManagement</li>
              <li>ContainerInstances</li>
              <li>ContainerRegistry</li>
              <li>KubernetesService</li>
            </ul>
          </div>
        </div>
      </ApiDescriptionSection>
    </ApiDescriptionContainer>
  );
};

export default DevelopersDataApiDescription;
