import React, { useContext } from 'react';
import CompanyTotalsContext from '../../contexts/CompanyTotalsContext/CompanyTotalsContext';
import './LineChartTooltip.css';
import { TdsButton } from '@scania/tegel-react';

const LineChartTooltip = (props) => {
  const companyTotalsContext = useContext(CompanyTotalsContext);

  const handleEmissionsDetailsClicked = () => {
    if (props.data && props.data.tooltipDisplay) {
      props.onClickEmissionsDetails(props.data.dataPointLabels[props.data.dataPointIndex]);
    }
  };

  const getTopPosition = () => {
    // Return default value if tooltip should be aligned to bottom position (only take first serie "index 0" in consideration)
    if (props.data.seriesMaxScales[0] / 2 >= props.data.series[0][props.data.dataPointIndex]) {
      return 'auto';
    }

    return props.data.top + 'px';
  };
  const getBottomPosition = () => {
    // Return default value if tooltip should be aligned to top position (only take first serie "index 0" in consideration)
    if (props.data.seriesMaxScales[0] / 2 < props.data.series[0][props.data.dataPointIndex]) {
      return 'auto';
    }

    return window.innerHeight - props.data.bottom + 'px';
  };
  const getLeftPosition = () => {
    // Return default value if tooltip should be aligned to right position
    if (
      props.data.dataPointVisibleLabelIndexes.length / 2 <=
      props.data.dataPointVisibleLabelIndexes.findIndex((i) => i == props.data.dataPointIndex) + 1
    ) {
      return 'auto';
    }

    return props.data.left + 'px';
  };
  const getRightPosition = () => {
    // Return default value if tooltip should be aligned to left position
    if (
      props.data.dataPointVisibleLabelIndexes.length / 2 >
      props.data.dataPointVisibleLabelIndexes.findIndex((i) => i == props.data.dataPointIndex) + 1
    ) {
      return 'auto';
    }

    return window.innerWidth - props.data.right + 'px';
  };

  return props.data ? (
    <div
      className="line-chart-tooltip-base"
      style={{
        top: getTopPosition(),
        bottom: getBottomPosition(),
        left: getLeftPosition(),
        right: getRightPosition(),
        display: props.data.tooltipDisplay ? 'block' : 'none'
      }}>
      <div className="p-2 line-chart-tooltip-header">
        {props.data.dataPointLabels[props.data.dataPointIndex]}
      </div>
      {props.data.series.map((serie, index) => {
        return (
          <div key={index} className="p-2">
            <div
              className="d-inline-block me-2 line-chart-tooltip-color-circle"
              style={{
                backgroundColor: props.data.seriesColors[index]
              }}
            />
            <span className="me-2">{props.data.seriesLabels[index]}:&nbsp;</span>
            <b>{serie[props.data.dataPointIndex]}</b>
          </div>
        );
      })}
      <div className="tds-u-flex tds-u-justify-center tds-u-p2">
        <TdsButton
          variant="primary"
          size="sm"
          onClick={handleEmissionsDetailsClicked}
          disabled={companyTotalsContext.active}
          text="Emissions Details"></TdsButton>
      </div>
    </div>
  ) : null;
};

export default LineChartTooltip;
