import React, { useContext, useEffect, useRef, useState } from 'react';
import LoadingContext from '../../../contexts/common/LoadingContext';
import useLocalStorage from 'use-local-storage';
import AccountAliasContext from '../../../contexts/accountAlias/AccountAliasContext';
import CustomerAccountsContext from '../../../contexts/Customer/CustomerAccountsContext';
import { TdsButton, TdsModal } from '@scania/tegel-react';
import './AccountAliasModal.css';

const AccountAliasModal = (props) => {
  const InputRef = useRef();
  const ErrorInputRef = useRef();
  const loadingContext = useContext(LoadingContext);
  const accountAliasContext = useContext(AccountAliasContext);
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const [blurSensitive] = useLocalStorage('blur', false);
  const [account, setAccount] = useState(undefined);
  const [aliasLength, setAliasLength] = useState(0);

  const handleChange = (e) => {
    setAliasLength(e.target.value.length);
  };

  const handleSubmit = () => {
    loadingContext.startActivity('SaveAlias', 'Saving account alias');
    let aliasInput;
    if (props.error) {
      aliasInput = ErrorInputRef.current.value;
    } else {
      aliasInput = InputRef.current.value;
    }
    if (aliasInput.trim() !== '') {
      accountAliasContext.setAlias(
        account,
        aliasInput.trim(),
        () => {
          props.handleDisplayModal(true);
          setAccount(undefined);
          loadingContext.stopActivity('SaveAlias');
        },
        () => {
          props.setError(true);
          loadingContext.stopActivity('SaveAlias');
        }
      );
    } else {
      accountAliasContext.deleteAlias(
        account,
        () => {
          props.handleDisplayModal(true);
          setAccount(undefined);
          loadingContext.stopActivity('SaveAlias');
        },
        () => {
          props.setError(true);
          loadingContext.stopActivity('SaveAlias');
        }
      );
    }
  };

  useEffect(() => {
    if (props.itemId) {
      const item = customerAccountsContext.accounts.filter(
        (item) => item.AccountId === props.itemId
      );
      setAccount(...item);
    }
  }, [props.itemId]);

  // work around for time lag in setting state
  useEffect(() => {
    if (InputRef.current) {
      InputRef.current.defaultValue = account?.Alias ? account.Alias : '';
      setAliasLength(account?.Alias ? account.Alias.length : 0);
    }
  }, [account]);

  //
  useEffect(() => {
    if (props.displayModal) {
      if (!account) {
        const item = customerAccountsContext.accounts.filter(
          (item) => item.AccountId === props.itemId
        );
        setAccount(...item);
      }
      InputRef.current.defaultValue = account?.Alias ? account.Alias : '';
      document.querySelector('[selector="alias-modal"]').showModal();
    }
  }, [props.displayModal]);

  return (
    <TdsModal
      show={props.displayModal}
      onTdsClose={() => {
        props.handleDisplayModal();
        props.setError(false);
      }}
      animation={false}
      centered={true}
      selector={'alias-modal'}
      header={'Edit account alias'}>
      <div slot={'body'}>
        <form
          // className="form-floating"
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          {!props.error ? (
            <>
              <label className="account-alias-modal-label">Account alias</label>
              <br />
              <div className="account-alias-modal-input-container">
                <input
                  type={blurSensitive ? 'password' : 'text'}
                  ref={InputRef}
                  id="floatingInputValue"
                  autoFocus
                  maxLength={40}
                  className="account-alias-modal-input"
                  onChange={handleChange}
                />
              </div>
              <div className="account-alias-modal-input-length-display">{aliasLength} / 40</div>
            </>
          ) : (
            <>
              <input
                type="text"
                ref={ErrorInputRef}
                className="form-control account-alias-modal-control is-invalid"
                id="floatingInputInvalid"
                maxLength={40}
              />
              <label htmlFor="floatingInputInvalid" className="account-alias-modal-control-label">
                Oops! Something went wrong...
              </label>
            </>
          )}
        </form>
        <div className="tds-u-mt2">
          <TdsButton
            variant="primary"
            size="sm"
            onClick={() => {
              document.querySelector('[selector="alias-modal"]').closeModal();
              handleSubmit();
            }}
            text={'Save'}
            type={'submit'}></TdsButton>
        </div>
      </div>
    </TdsModal>
  );
};

export default AccountAliasModal;
