import React, { useContext } from 'react';
import ApiUserContext from '../../../contexts/Auth/ApiUserContext';
import ApiKeysTable from './ApiKeysTable';
import LoadingContent from '../../../components/common/LoadingContent';
import CreateApiKey from './CreateApiKey';
import config from './../../../config';
import ApiKeyModal from './ApiKeyModal';
import { TdsButton } from '@scania/tegel-react';

const ApiKeyActions = () => {
  const apiUserContext = useContext(ApiUserContext);

  const documentationClick = () => {
    window.open(config.DEVELOPERS_SITE, '_blank', 'noreferrer');
  };

  const handleClick = () => {
    if (apiUserContext?.apiUserOne) {
      apiUserContext.createApiUserTwo();
    } else {
      apiUserContext.createApiUserOne();
    }
  };
  const apiUsersPresent = () => {
    if (apiUserContext?.apiUserOne && apiUserContext?.apiUserTwo) {
      return 'two';
    } else if (apiUserContext?.apiUserOne || apiUserContext?.apiUserTwo) {
      return 'one';
    } else {
      return false;
    }
  };
  return !apiUserContext.isLoading && !apiUserContext.isCallingBackend() ? (
    <>
      {(apiUserContext.apiKeyOne || apiUserContext.apiKeyTwo) && <ApiKeyModal />}
      <div className="tds-row">
        <div className="tds-col">
          {apiUsersPresent() ? (
            <>
              <div className="tds-headline-05 tds-u-mb2">Your Keys</div>
              <ApiKeysTable />
            </>
          ) : (
            <>
              <CreateApiKey handleClick={handleClick} />
            </>
          )}

          {apiUsersPresent() === 'one' && (
            <>
              <div className="tds-u-mt3 tds-u-mb3">
                <TdsButton
                  size="md"
                  disabled={apiUserContext.isCallingBackend()}
                  onClick={handleClick}
                  text="Generate API key"></TdsButton>
                <TdsButton
                  variant="secondary"
                  size="md"
                  className="tds-u-ml1"
                  disabled={apiUserContext.isCallingBackend()}
                  onClick={documentationClick.bind(this)}
                  text="Read Our Documentation"></TdsButton>
              </div>
              <div className="tds-u-mt3">
                <div className="tds-headline-06">Information and recommendations</div>
                <ul>
                  <li className="tds-u-pb1">
                    In order to allow rotation of keys without any downtime we support two keys to
                    be generated and active at the same time.
                  </li>
                  <li className="tds-u-pb1">
                    When used, the keys will impersonate you and your permissions, meaning
                    everything you can access in our application is also accessible with your API
                    keys.
                  </li>
                  <li>
                    <b>IMPORTANT:</b> Be sure to keep the keys safe, preferably encrypted and/or
                    stored in an isolated storage!
                  </li>
                </ul>
              </div>
            </>
          )}
          {apiUsersPresent() === 'two' && (
            <div>
              <div className="tds-u-mt3 tds-u-mb3">
                <TdsButton
                  variant="primary"
                  text="Read Our Documentation"
                  size="md"
                  disabled={apiUserContext.isCallingBackend()}
                  onClick={documentationClick.bind(this)}></TdsButton>
              </div>
              <div>
                <div className="tds-headline-06">Information and recommendations</div>
                <ul>
                  <li className="tds-u-pb1">
                    In order to allow rotation of keys without any downtime we support two keys to
                    be generated and active at the same time.
                  </li>
                  <li className="tds-u-pb1">
                    When used, the keys will impersonate you and your permissions, meaning
                    everything you can access in our application is also accessible with your API
                    keys.
                  </li>
                  <li>
                    <b>IMPORTANT:</b> Be sure to keep the keys safe, preferably encrypted and/or
                    stored in an isolated storage!
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <LoadingContent loadingHeading={'Retreiving API key status'} />
  );
};

export default ApiKeyActions;
