import React, { useState } from 'react';
import FilterDropdown from '../../molecules/Filter/FilterDropdown';
import HandleOutsideClickAndEsc from '../../atoms/Handlers/HandleOutsideClickAndEsc';
import FilterDropdownButton from '../../molecules/Filter/FilterDropdownButton';
import ServicesFilterContext from '../../../contexts/FilterContexts/ServicesFilterContext';
import { useCookies } from 'react-cookie';

function ServicesFilter({
  handlers = [],
  closeOnClickOutside = undefined,
  controlId = 'DEFAULT',
  filterId = undefined
}) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [cookies] = useCookies(['FunctionalCookieConsent']);

  const onClickDropdown = () => setDisplayDropdown(!displayDropdown);
  const closeDropdown = () => setDisplayDropdown(false);

  return (
    <HandleOutsideClickAndEsc enabled={closeOnClickOutside} handler={closeDropdown}>
      <FilterDropdownButton
        itemTypeLabel="services"
        onClickDropdownHandler={onClickDropdown}
        displayDropdown={displayDropdown}
      />
      <FilterDropdown
        controlId={controlId}
        filterId={filterId}
        handlers={[...handlers, closeDropdown]}
        displayed={displayDropdown}
        itemTypeLabel="SERVICES"
        itemsContextType={ServicesFilterContext}
        selectAllEnabled={true}
        favouritesEnabled={cookies.FunctionalCookieConsent ? true : false}
        emptyContent={
          <span>
            Please adjust your <b>PROVIDER</b> selection
          </span>
        }
      />
    </HandleOutsideClickAndEsc>
  );
}

// Set default properties
// ServicesFilter.defaultProps = {
//   controlId: 'DEFAULT', // TODO: Autogenerate unique ID here
//   filterId: undefined,
//   handlers: [],
//   closeOnClickOutside: undefined
// };

export default ServicesFilter;
