import {
  TdsIcon,
  TdsSideMenu,
  TdsSideMenuCloseButton,
  TdsSideMenuDropdown,
  TdsSideMenuDropdownList,
  TdsSideMenuDropdownListItem,
  TdsSideMenuItem,
  TdsSideMenuOverlay
} from '@scania/tegel-react';
import { Link, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import AuthContext from '../../contexts/common/AuthContext';
import MenuContext from '../../contexts/MenuContext/MenuContext';
import { app_base_path_with_leading_slash } from '../../config';
import { styled } from 'styled-components';

const Main = styled.main`
  margin-top: 64px;
`;

const Icon = styled.img`
  width: 24px;
`;

const SideMenu = styled.div`
  min-height: calc(100% - var(--scania-footer-height));
`;

function ScaniaSideMenu({ children }) {
  const auth = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const { pathname } = useLocation();

  return (
    <>
      {auth.isLoggedIn() ? (
        <SideMenu className="tds-u-flex tds-u-flex-dir-col">
          <TdsSideMenu className={'tds-hide-lg'} open={menuContext.mobileNavExpanded}>
            <TdsSideMenuOverlay
              onClick={menuContext.toggleMobileNav}
              slot="overlay"></TdsSideMenuOverlay>
            <TdsSideMenuCloseButton
              onClick={menuContext.toggleMobileNav}
              slot="close-button"></TdsSideMenuCloseButton>
            <TdsSideMenuItem selected={pathname === app_base_path_with_leading_slash + '/home'}>
              <Link
                to={app_base_path_with_leading_slash + '/home'}
                onClick={menuContext.toggleMobileNav}>
                <TdsIcon name="home" size="24"></TdsIcon>
                Home
              </Link>
            </TdsSideMenuItem>
            <TdsSideMenuDropdown>
              <Icon
                src={app_base_path_with_leading_slash + '/images/emissions.svg'}
                alt="Emissions graph."
                slot={'icon'}
              />
              <span slot="label">Emissions</span>
              <TdsSideMenuDropdownList>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/emissions'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/emissions'}
                    onClick={menuContext.toggleMobileNav}>
                    Details
                  </Link>
                </TdsSideMenuDropdownListItem>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/compare'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/compare'}
                    onClick={menuContext.toggleMobileNav}>
                    Compare
                  </Link>
                </TdsSideMenuDropdownListItem>
              </TdsSideMenuDropdownList>
            </TdsSideMenuDropdown>

            <TdsSideMenuDropdown>
              <TdsIcon slot="icon" name="document" size="24"></TdsIcon>
              <span slot="label">Documentation</span>
              <TdsSideMenuDropdownList>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/methodology'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/methodology'}
                    onClick={menuContext.toggleMobileNav}>
                    Methodology
                  </Link>
                </TdsSideMenuDropdownListItem>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/recommendations'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/recommendations'}
                    onClick={menuContext.toggleMobileNav}>
                    Recommendations
                  </Link>
                </TdsSideMenuDropdownListItem>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/developers'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/developers'}
                    onClick={menuContext.toggleMobileNav}>
                    Developers
                  </Link>
                </TdsSideMenuDropdownListItem>
                <TdsSideMenuDropdownListItem
                  selected={pathname === app_base_path_with_leading_slash + '/integration'}>
                  <Link
                    to={app_base_path_with_leading_slash + '/integration'}
                    onClick={menuContext.toggleMobileNav}>
                    Integration
                  </Link>
                </TdsSideMenuDropdownListItem>
              </TdsSideMenuDropdownList>
            </TdsSideMenuDropdown>
          </TdsSideMenu>
          <Main>{children}</Main>
        </SideMenu>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default ScaniaSideMenu;
