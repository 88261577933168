import React from 'react';

function RecommendationsRearchitecting() {
  return (
    <React.Fragment>
      <p className={'tds-body-01'}>
        <h2 id="rearchitecting">Re-architecting</h2>
        <p>
          There are many ways one can design or architect a system. Aim for architecture that uses
          the computer in the most optimal way while minimizing the CO2e emissions.
        </p>
        <p>Some of these architecturing changes might be:</p>
        <ul>
          <li>Choose serverless over servers</li>
          <li>
            Choose energy-efficient programming languages (scientific study{' '}
            <a
              href="https://greenlab.di.uminho.pt/wp-content/uploads/2017/10/sleFinal.pdf"
              rel="noreferrer"
              target="_blank">
              here
            </a>
            )
          </li>
          <li>
            Spread out batch processes over time and/or dont execute on exactly whole hours (e.g.
            04:00)
          </li>
          <li>
            Run batch processes on times when there is little other traffic/jobs in the region to
            utilize resources that might be idling
          </li>
        </ul>
      </p>
    </React.Fragment>
  );
}

export default RecommendationsRearchitecting;
