import React from 'react';
import RecommendationsIntro from '../../components/RecommendationsPage/RecommendationsIntro';
import RecommendationsRelocation from '../../components/RecommendationsPage/RecommendationsRelocation';
import RecommendationsRightsizing from '../../components/RecommendationsPage/RecommendationsRightsizing';
import RecommendationsRearchitecturing from '../../components/RecommendationsPage/RecommendationsRearchitecting';
import RecommendationsThreeRs from '../../components/RecommendationsPage/RecommendationsThreeRs';
import RecommendationsElectricityMap from '../../components/RecommendationsPage/RecommendationsElectricityMap';

function Recommendations() {
  return (
    <div className={'tds-container'}>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <p className={'tds-body-01'}>
            Everyone can do something to improve and lower the climate impact; regardless if you are
            Greta Thunberg, a truck driver that utilizes eco-driving or a system developer that
            strive toward being a sustainable and green developer.
            <br />
            Here are some methods and principles that we wish to share in order to boost the future
            of sustainable developers!
          </p>
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <RecommendationsIntro />
        </div>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <RecommendationsThreeRs />
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <RecommendationsRelocation />
        </div>
        <div className={'tds-col-lg-4 tds-col-xs-12'}>
          <div className={'recommendations-relocation-col-image'}>
            <RecommendationsElectricityMap />
          </div>
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <RecommendationsRightsizing />
        </div>
      </div>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <RecommendationsRearchitecturing />
        </div>
      </div>
    </div>
    // <React.Fragment>
    //   <TitlePanel title="General Recommendations">
    //     <div>
    //       Everyone can do something to improve and lower the climate impact; regardless if you are
    //       Greta Thunberg, a truck driver that utilizes eco-driving or a system developer that strive
    //       toward being a sustainable and green developer.
    //       <br />
    //       Here are some methods and principles that we wish to share in order to boost the future of
    //       sustainable developers!
    //     </div>
    //   </TitlePanel>
    //   <RecommendationsIntro />
    //   <RecommendationsRelocation />
    //   <RecommendationsRightsizing />
    //   <RecommendationsRearchitecturing />
    // </React.Fragment>
  );
}

export default Recommendations;
